import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import KioskRequests from "../components/My Company/KioskRequests";
import KioskSessions from "../components/My Company/KioskSessions";
import { useTheme } from "@mui/material";

const Kiosk = () => {
    const [value, setValue] = React.useState(0);
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = useTheme();

    return (
        <div style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
        }}>
            <div style={{ flex: '0 0 auto', width: 'max-content', height: "100%", margin: isMobile ? "auto" : "0 20px 0 0" }}>
                <h1 style={{ textAlign: 'center' }}>Kiosk</h1>
                <Box sx={{
                    borderRadius: '10px',
                    margin: '0 auto',
                    marginBottom: '20px'
                }}>
                    <Tabs
                        // orientation="vertical"
                        orientation={isMobile ? 'horizontal' : 'vertical'}
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                    >
                        <Tab
                            label="Requests"
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: '1.2rem',
                                fontWeight: 600,
                            }}
                        />
                        <Tab
                            label="Sessions"
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: '1.2rem',
                                fontWeight: 600,
                            }}
                        />
                    </Tabs>
                </Box>
            </div>
            <div style={{ flex: '1' }}>
                {/* Content for each tab goes here */}
                {value === 0 && <KioskRequests />}
                {value === 1 && <KioskSessions />}
            </div>
        </div>
    );
};

export default Kiosk;
