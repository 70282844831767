import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import axios from "axios";
import { CircularProgress, useTheme, useThemeProps } from "@mui/material";
import SuccessAlerts from "../../SuccessAlert";
import ErrorAlerts from "../../ErrorAlert";

export default function InviteEmployee(props) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);


  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const submitHandler = (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please provide an email");
    }
    const config = {
      headers: {
        "Content-Type": "Application/json",
          Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_BACKEND}/api/${userInfo.companyId}/${userInfo.currentBranchId}/email`, { email: email.toLowerCase() }, config)
      .then((res) => {
        setLoading(false);
        setSuccess(res.data.message);
        props.fetchEmployees();
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
      });
  };
  if (success) {
    setTimeout(() => {
      setSuccess("");
    }, 5000);
  }
  if (error) {
    setTimeout(() => {
      setError("");
    }, 5000);
  }
  const handleClickOpen = () => {
    setOpen(true);
    setEmail("");
  };

  const handleClose = () => {
    setOpen(false);
    setSuccess("");
    setError("");
  };
  const theme = useTheme();

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        style={{
          textTransform: "capitalize",
          fontWeight: "600",
        }}
      >
        <AddIcon /> Invite employee
      </Button>

      {/* <Button variant="outlined" >
        Open form dialog
      </Button> */}

      <Dialog open={open} onClose={handleClose}>
        <div style={{ textAlign: "center", margin: "auto", marginTop: "1rem" }}>
          {loading && <CircularProgress color="secondary" />}
          {error && <ErrorAlerts error={error} />}
          {success && <SuccessAlerts success={success} />}
        </div>
        <DialogTitle>Invite Employee</DialogTitle>
        <form onSubmit={submitHandler}>
          <DialogContent>
            <DialogContentText style={{ color: theme.palette.text.primary }}>
              To invite an employee, please enter their email address here.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              sx={{ "& label": { color: theme.palette.text.primary } }}
              type="email"
              fullWidth
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Invite</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
