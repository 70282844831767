import React from "react";
import OrdersHeader from "../components/Purchase Orders/Orders/OrdersHeader";
import OrdersTable from "../components/Purchase Orders/Orders/OrdersTable";
import UnderDevelopmentMessage from "../components/UnderDevelopment/UnderDevelopmentMessage";

function Orders() {
  return (

    <div >
      <UnderDevelopmentMessage/>
      <OrdersHeader />
      <OrdersTable />
    </div>
  );
}

export default Orders;
