import React, { useEffect, useState } from 'react';
import {
   TextField,
   Button,
   Drawer,
   Box,
   Typography,
   List,
   ListItem,
   ListItemText,
   IconButton,
   Divider,
   FormControlLabel,
   Checkbox,
   Dialog,
   Paper,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Close, Delete } from '@mui/icons-material';
import axios from 'axios';
import SuccessAlerts from '../SuccessAlert';
import ErrorAlerts from '../ErrorAlert';
import { useTheme } from '@emotion/react';


const RoleSettings = () => {
   const theme = useTheme();
   const [roleName, setRoleName] = useState('');
   const [roleDescription, setRoleDescription] = useState('');
   const [permissions, setPermissions] = useState({});
   const [isAddRoleSidebarOpen, setIsAddRoleSidebarOpen] = useState(false);
   const [isEditRoleSidebarOpen, setIsEditRoleSidebarOpen] = useState(false);
   const [error, setError] = useState("");
   const [success, setSuccess] = useState("");
   const [roles, setRoles] = useState([]);
   const [modules, setModules] = useState([]);
   const [open, setOpen] = useState(false);
   const [errorMailIds, setErrorMailIds] = useState([]);
   const [errorRoleId, setErrorRoleId] = useState("");
   const [editRoleId, setEditRoleId] = useState("");

   const userInfo = JSON.parse(localStorage.getItem('userInfo'));
   const token = userInfo.token;
   const config = {
      headers: {
         "Content-type": "application/json",
         Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
   };


   const handleRoleNameChange = (event) => {
      setRoleName(event.target.value);
   };

   function getRandomColor() {
      let color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
      return color;
   }

   const handleRoleDescriptionChange = (event) => {
      setRoleDescription(event.target.value);
   };

   const handlePermissionChange = (module, type) => (event) => {
      const updatedPermissions = {
         ...permissions,
         [module]: {
            ...permissions[module],
            [type]: event.target.checked,
         },
      };
      setPermissions(updatedPermissions);
   };



   const handleUpdateRole = () => {
      let requestData = {
         role: roleName,
         description: roleDescription,
      };
      let PermissionArray = [];
      modules.forEach((module) => {
         PermissionArray.push({
            moduleId: module.id,
            read: permissions[module.name]?.read || false,
            write: permissions[module.name]?.write || false,
         });
      });
      requestData = {
         ...requestData,
         permissions: PermissionArray,
      };
      const updatedRole = {
         role: roleName,
         description: roleDescription
      };
      axios.put(`${process.env.REACT_APP_API_BACKEND}/api/updateRole/${editRoleId}`, requestData, config).then((response) => {
         fetchRoles();
         setSuccess(response.data.message);
         //close sidebar
         setIsEditRoleSidebarOpen(false);
      }).catch((error) => {
         console.log(error);
         setError(error.response.data.message);
      });
      setRoleName('');
      setRoleDescription('');
      setPermissions({});
      setTimeout(() => {
         setSuccess("");
         setError("");
      }, 3000);
   };


   const handleCreateRole = (index) => {
      let requestData = {
         role: roleName,
         description: roleDescription,
      };
      let permissionsArray = [];
      modules.forEach((module) => {
         permissionsArray.push({
            moduleId: module.id,
            read: permissions[module.name]?.read || false,
            write: permissions[module.name]?.write || false,
         });
      });
      requestData = {
         ...requestData,
         permissions: permissionsArray,
      };
      const newRole = {
         role: roleName,
         description: roleDescription
      };

      axios.post(`${process.env.REACT_APP_API_BACKEND}/api/createRole/${userInfo.currentBranchId}`, requestData, config).then((response) => {
         setRoles([...roles, newRole]);
         setSuccess(response.data.message);
         //close sidebar
         setIsAddRoleSidebarOpen(false);
      }).catch((error) => {
         console.log(error);
         setError(error.response.data.message);
      });
      setRoleName('');
      setRoleDescription('');
      setPermissions({});
      setTimeout(() => {
         setSuccess("");
         setError("");
      }, 3000);
   };

   const fetchRoles = () => {
      axios.get(`${process.env.REACT_APP_API_BACKEND}/api/getRoles/${userInfo.currentBranchId}`, config).then((response) => {
         setRoles(response.data);
      }).catch((error) => {
         setError(error.response.data.message);
      }
      );
      setTimeout(() => {
         setError("");
      }
         , 3000);
   };
   useEffect(() => {
      fetchRoles();
   }, []);


   const handleDelete = (id, forceDelete = false) => {
      if (forceDelete) {
         axios.delete(`${process.env.REACT_APP_API_BACKEND}/api/deleteRole/${id}?forceDelete=true`, config).then((response) => {
            setSuccess(response.data.message);
            fetchRoles();
         }).catch((error) => {
            setError(error.response.data.message);
         });

         setOpen(false);
      }
      else {
         axios.delete(`${process.env.REACT_APP_API_BACKEND}/api/deleteRole/${id}`, config).then((response) => {
            setSuccess(response.data.message);
            fetchRoles();
         }).catch((error) => {
            //setError(error.response.data.message);
            if (error.response.data.message == "Role is assigned to employees") {
               setErrorRoleId(id);
               setErrorMailIds(error.response.data.mailIds);
               setOpen(true);
            } else {
               setError(error.response.data.message);
            }
         });
      }
      setTimeout(() => {
         setSuccess("");
         setError("");
      }, 5000);
   };



   const handleEditRole = (index) => {
      setPermissions({});
      setEditRoleId(roles[index].id);
      setIsEditRoleSidebarOpen(true);
      setRoleName(roles[index].role);
      setRoleDescription(roles[index].description);
      const updatedPermissions = {};
      modules.forEach((module) => {
         updatedPermissions[module.name] = {
            read: roles[index].permissions.find((permission) => permission.moduleId === module.id)?.read || false,
            write: roles[index].permissions.find((permission) => permission.moduleId === module.id)?.write || false,
         };
      });
      setPermissions(updatedPermissions);
   };
   const fetchModules = () => {
      axios.get(`${process.env.REACT_APP_API_BACKEND}/api/getModules`, config).then((response) => {
         setModules(response.data);
      }).catch((error) => {
         setError(error.response.data.message);
      }
      );
      setTimeout(() => {
         setError("");
      }
         , 3000);
   };
   useEffect(() => {
      fetchModules();
   }, []);

   const renderPermissionCheckboxes = () => {
      return (
         <Box>
            {modules.map((module) => (
               <div key={module.id} style={{ marginBottom: '16px' }}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }} >
                     {module.name}
                  </Typography>
                  <FormControlLabel
                     control={
                        <Checkbox
                           sx={{ color: theme.palette.text.primary }}
                           checked={permissions[module.name]?.read || false}
                           onChange={handlePermissionChange(module.name, 'read')}
                        />
                     }
                     label="Read"
                  />
                  <FormControlLabel
                     control={
                        <Checkbox
                           sx={{ color: theme.palette.text.primary }}
                           checked={permissions[module.name]?.write || false}
                           onChange={handlePermissionChange(module.name, 'write')}
                        />
                     }
                     label="Write"
                  />
               </div>
            ))}
         </Box>
      );
   };

   return (
      <div style={{ display: 'flex', padding: '20px' }}>
         {success && <SuccessAlerts success={success} />}
         {error && <ErrorAlerts error={error} />}
         <Drawer
            variant="temporary"
            anchor="right"
            PaperProps={
               { style: { backgroundColor: theme.palette.card.bg } }
            }

            open={isAddRoleSidebarOpen || isEditRoleSidebarOpen}
            onClose={() => {
               setIsAddRoleSidebarOpen(false);
               setIsEditRoleSidebarOpen(false);
               setRoleName('');
               setRoleDescription('');
               setPermissions({});

            }}
            sx={{ zIndex: 10000 }}
         >
            <div onClick={() => {setIsAddRoleSidebarOpen(false);}}
               style={{ position: "absolute", right: "10px", top: "10px", cursor: "pointer" }}>
               <Close />
            </div>
            <Box style={{ margin: "25px", fontSize: "1.5rem" }}>
               <Typography variant="h5" gutterBottom>
                  {isAddRoleSidebarOpen ? 'Add Role' : 'Edit Role'}
               </Typography>
               <TextField
                  label="Role Name"
                  value={roleName}
                  onChange={handleRoleNameChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                     style: { color: theme.palette.text.primary }
                  }}

                  style={{ marginBottom: '16px' }}
               />
               <TextField
                  label="Role Description"
                  value={roleDescription}
                  onChange={handleRoleDescriptionChange}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  style={{ marginBottom: '16px' }}
                  InputLabelProps={{
                     style: { color: theme.palette.text.primary }
                  }}
               />
               {renderPermissionCheckboxes()}
               <Button variant="contained" color="primary" onClick={isAddRoleSidebarOpen ? handleCreateRole : handleUpdateRole}>
                  Save Role
               </Button>
            </Box>
         </Drawer>
         <div style={{ flex: 1, width: '100%' }}>
            <Button onClick={() => setIsAddRoleSidebarOpen(true)} style={{ marginBottom: '16px' }}>
               <AddCircleOutlineIcon />
               Add Role
            </Button>
            <List>
               <Paper elevation={3} sx={{ padding: 2 }}>
                  {roles.map((role, index) => (
                     <div key={index}>
                        <ListItem sx={{ marginBottom: '10px', boxShadow: '0px 0px 5px 0px rgba(0,10,0,0.2)', }}>
                           {/* <ListItemText primary={role.role} secondary={role.description} /> */}
                           <ListItemText>
                              <Typography sx={{ fontSize: "20px", fontWeight: "500" }} >
                                 {role.role}
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                 {role.description}
                              </Typography>
                           </ListItemText>

                           <IconButton onClick={() => handleEditRole(index)} sx={{ marginLeft: '20px' }}>
                              <EditIcon />
                           </IconButton>
                           <IconButton onClick={() => handleDelete(role.id)}>
                              <Delete />
                           </IconButton>
                        </ListItem>
                        <Divider />
                     </div>
                  ))}
               </Paper>
            </List>
         </div>

         <Dialog
            open={open}
            onClose={() => { }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >

            <div style={{ padding: "20px", width: "500px" }}>
               <Typography gutterBottom sx={{ fontWeight: "500", fontSize: "20px" }}>
                  This role is assigned to following employees:
               </Typography>
               <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
                  {errorMailIds.map((mailId) => (
                     <div style={{ backgroundColor: `${getRandomColor()}`, padding: "5px", borderRadius: "5px", margin: "5px" }}>
                        {mailId}
                     </div>
                  ))}
                  <Typography variant="h6" gutterBottom>
                     If you delete this role, all the employees will be assigned Basic role.
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                     Do you want to continue?
                  </Typography>
                  <Button
                     variant="contained"
                     color="primary"
                     onClick={() => handleDelete(errorRoleId, true)}
                     sx={{ marginRight: "10px" }}
                  >
                     Yes
                  </Button>
                  <Button
                     variant="contained"
                     color="secondary"
                     onClick={() => setOpen(false)}
                     sx={{ marginLeft: "10px" }}
                  >
                     No
                  </Button>
               </div>
            </div>
         </Dialog>
      </div>
   );
};

export default RoleSettings;

