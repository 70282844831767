import React, { useEffect } from 'react';
import {Route, useLocation,useHistory, Redirect} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
function ProtectedRoutes(props) {
    const location = useLocation();
    const history = useHistory();
    const userLogin = useSelector((state) => state.userLogin);
    const { error, loading, userInfo } = userLogin;
  useEffect(() => {
      if(!userInfo){
   history.push('/login')
}
  }, [userInfo]);
  
    return userInfo ? (
      <Route {...props} />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location }
        }}
      />
    );
}

export default ProtectedRoutes;
