import React, { useState, useEffect } from "react";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, IconButton, InputAdornment, InputBase, Paper, Radio, RadioGroup, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, useTheme, Drawer, List, ListItem, ListItemText, ListItemIcon, Typography } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { useSelector } from "react-redux";
import { addDays, set } from 'date-fns';
import SearchIcon from "@mui/icons-material/Search";
import { TailSpin } from "react-loader-spinner";
import { toPng } from 'html-to-image';
import WeekPicker from "./WeekPicker";
import DayRange from "./DayRange";
import MenuIcon from "@mui/icons-material/Menu";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import InfoIcon from "@mui/icons-material/Info";


// custom styling for input text field
const styles = (theme) => ({
  input: {
    height: 40,
    paddingRight: 0,
  },
});

const MobileScheduleHeader = withStyles(styles)((props) => {
  const { classes, search, setSearch, loading, handleSearch, handleEmployeeSearch, handlePreviousWeek, handleNextWeek, handleWeekChange, week, totalPublished, totalUnPublished, fetch, setScheduleArray, allScheduleArray, summary } = props;

  let currWeek = localStorage.getItem('week');
  if (!currWeek) {
    currWeek = week;
  }
  const getNewWeek = (week) => {
    const weekArray = week.split("-W");
    const year = weekArray[0];
    const weekNumber = weekArray[1];
    // const newWeekNumber = parseInt(weekNumber) + 1;
    if (weekNumber === "52") {
      return `${parseInt(year) + 1}-W01`;
    }
    if (parseInt(weekNumber) < 9) {
      return `${year}-W0${parseInt(weekNumber) + 1}`;
    }
    return `${year}-W${parseInt(weekNumber) + 1}`;
  };
  const getPrevWeek = (week) => {
    //if week is 2022-W01 TO 2021-W52
    const weekArray = week.split("-W");
    const year = weekArray[0];
    const weekNumber = weekArray[1];
    // const newWeekNumber = parseInt(weekNumber) + 1;
    if (weekNumber === "01") {
      return `${parseInt(year) - 1}-W52`;
    }
    if (parseInt(weekNumber) < 11) {
      return `${year}-W0${parseInt(weekNumber) - 1}`;
    }
    return `${year}-W${parseInt(weekNumber) - 1}`;
  };


  const [status, setStatus] = useState("All");
  const [employees, setEmployees] = useState([]);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 7));
  const [PublishDialog, setPublishDialog] = useState(false);
  const [publishType, setPublishType] = useState("unpublished");
  const [toNotification, setToNotification] = useState("true");
  const [scheduleDialogMsg, setScheduleDialogMsg] = useState("");
  const [scheduleDialog, setScheduleDialog] = useState(false);
  const [scheduleDialogBg, setScheduleDialogBg] = useState("");
  const [copyScheduleDialog, setCopyScheduleDialog] = useState(false);
  const [copyFrom, setCopyFrom] = useState(currWeek);
  const [copyTo, setCopyTo] = useState(getNewWeek(currWeek));
  const [copyOptions, setCopyOptions] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [department, setDepartment] = useState("All");
  const [overLapDialog, setOverLapDialog] = useState(false);
  const [overLapData, setOverLapData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);


  const handleRemoveDepartment = (dept) => {
    const newSelectedDepartments = selectedDepartments.filter((department) => department !== dept);
    setSelectedDepartments(newSelectedDepartments);
  }


  const toggle = () => setOpen(!open);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };
  const handleWeekOptions = () => {
    setCopyFrom(currWeek);
    setCopyTo(getNewWeek(currWeek));
    let tempWeek = localStorage.getItem('week');
    const copyOptions = [];
    for (let i = 0; i < 5; i++) {
      tempWeek = getPrevWeek(tempWeek);
      copyOptions.push(tempWeek);
    }
    tempWeek = currWeek;
    copyOptions.push(tempWeek);

    for (let i = 0; i < 5; i++) {
      tempWeek = getNewWeek(tempWeek);
      copyOptions.push(tempWeek);
    }
    copyOptions.sort();
    setCopyOptions(copyOptions);

  };
  const clickPNG = () => {
    toPng(document.getElementById('schedule-table'))
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = 'schedule.png';
        link.href = dataUrl;
        link.click();
      });
  }



  const handleDeleteAll = () => {
    const data = {
      "week": week,
    }

    axios.post(`${process.env.REACT_APP_API_BACKEND}/api/schedule/deleteForWeek`, data, config).then((res) => {
      setScheduleDialogMsg(res.data.message);
      fetch(week);
      setDeleteDialog(false);
      setScheduleDialogBg("#4caf50");
      setScheduleDialog(true);
    }).catch(err => {
      fetch(week);
      setDeleteDialog(false);
    })
    setDeleteDialog(false);
  }
  const theme = useTheme();
  const selectAllDepartments = () => {
    const initialSelectedDepartments = summary.map((dept) => dept.department);
    setSelectedDepartments(initialSelectedDepartments);
  }

  const copySchedule = () => {
    const data = {
      "fromWeek": copyFrom,
      "toWeek": copyTo,
      "departments": selectedDepartments,
    }
    axios.post(`${process.env.REACT_APP_API_BACKEND}/api/schedule/copy`,
      data,
      config)
      .then(res => {
        if (res.data.success === true) {
          setScheduleDialogMsg(res.data.message);
          setScheduleDialogBg("#4caf50");
          setScheduleDialog(true);
          setTimeout(() => {
            setScheduleDialog(false);
          }
            , 5000);

        } else {
          setScheduleDialogMsg(res.data.message);
          setScheduleDialogBg("#f44336");
        }
        fetch(week);
        setCopyScheduleDialog(false);
        // setScheduleDialogMsg(res.data.message);
        // setScheduleDialogBg("#4caf50");
        if (res.data.overlap === true) {
          setOverLapDialog(true);
          setOverLapData(res.data.overLapSummary);
        }

      }).catch(err => {
        setScheduleDialogMsg(err.response.data.message);
        setScheduleDialogBg("#f44336");
        setScheduleDialog(true);
        fetch(week);
        setCopyScheduleDialog(false);
        selectAllDepartments();
        setTimeout(() => {
          setScheduleDialog(false);
        }
          , 3000);

      })
  }

  const handleCopyScheduleDialog = () => {
    handleWeekOptions();
    selectAllDepartments();
    setCopyScheduleDialog(!copyScheduleDialog);
  }

  const handlePublishDialog = () => {
    selectAllDepartments();
    setPublishDialog(!PublishDialog);
  };


  const handleSortByDepartment = (e) => {
    setDepartment(e.target.value);
    if (e.target.value === "All") {
      setScheduleArray(allScheduleArray);
    } else {
      const filteredArray = allScheduleArray.filter((department) => {
        return department.department === e.target.value;
      });
      setScheduleArray(filteredArray);
    }
  };


  const publishShifts = async () => {
    const data = {
      "week": week,
      "companyName": userInfo.companyName,
      "type": publishType,
      "notify": toNotification === "true" ? true : false,
      "departments": selectedDepartments,
    }
    await axios.post(`${process.env.REACT_APP_API_BACKEND}/api/schedule/publish`, data, config).then((res) => {
      setScheduleDialogMsg(res.data.message);
      fetch(week);
      setPublishDialog(false);
      setScheduleDialogBg("#4caf50");
      setScheduleDialog(true);
    }).catch(err => {
      fetch(week);
      setPublishDialog(false);
    })
    setPublishDialog(false);

  };

  function formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth();
    let monthName = getMonthName(month);

    return `${day} ${monthName}`;
  }

  function getMonthName(month) {
    let shortMonths = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return shortMonths[month];
  }


  function stringifyWeek(week) {
    let parts = week.split("-W");
    let year = parseInt(parts[0]);
    let weekNum = parseInt(parts[1]);

    let date = new Date(year, 0, 4 + (weekNum - 1) * 7); // ISO weeks start from Monday, so adjust the starting point
    let weekStart = new Date(date.setDate(date.getDate() - (date.getDay() + 6) % 7));
    let weekEnd = new Date(date.setDate(date.getDate() + 6));

    let start = formatDate(weekStart);
    let end = formatDate(weekEnd);

    return `${start} - ${end}`;
  }

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return(
    <div>
      <Paper
        className="header"
        style={{
          padding: "1rem",
          flexDirection: "column",
          borderCollapse: "separate",
          marginBottom: "1rem"
        }}
      >
        <Snackbar
          open={scheduleDialog}
          onClose={() => setScheduleDialog(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            top: { md: 75 }
          }}
          onExited={() => setScheduleDialogBg("")}
        >
          <div style={{ backgroundColor: scheduleDialogBg, color: "#fff", padding: "10px", borderRadius: "5px" }}>
            {scheduleDialogMsg}
          </div>
        </Snackbar>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.5rem" }}>
          <Typography variant="h6" style={{ fontWeight: 600 }}>Schedule</Typography>
          <IconButton 
            onClick={toggleDrawer(true)} 
            size="small" 
            sx={{ 
              backgroundColor: theme.palette.primary.main,
              color: "#fff",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              }
            }}
          >
            <MenuIcon />
          </IconButton>
        </div>

        <div>
          <DayRange week={week} isMobile={true}/>
          <div style={{ marginBottom: "0.5rem" }}>
            {
              loading ?
                <>
                  <span style={{ backgroundColor: theme.palette.success.main, fontWeight: "500", color: theme.palette.text.secondary, margin: "5px", borderRadius: "5px", padding: "2px 4px" }}>0 published</span>
                  <span style={{ backgroundColor: theme.palette.info.main, fontWeight: "500", color: theme.palette.text.secondary, margin: "5px", borderRadius: "5px", padding: "2px 4px" }}>0 unpublished</span>
                </> : <>
                  <span style={{ backgroundColor: theme.palette.success.main, fontWeight: "500", color: theme.palette.text.secondary, margin: "5px", borderRadius: "5px", padding: "2px 4px" }}>{totalPublished} published</span>
                  <span style={{ backgroundColor: theme.palette.info.main, fontWeight: "500", color: theme.palette.text.secondary, margin: "5px", borderRadius: "5px", padding: "2px 4px" }}>{totalUnPublished} unpublished</span>
                </>}
          </div>
        </div>

        <div style={{ marginBottom: "0.75rem" }}>
          <TextField
            id="outlined-basic"
            value={search}
            onChange={handleEmployeeSearch}
            style={{ width: "100%" }}
            autoComplete="off"
            placeholder="Search by employee"
            sx={{ 
              backgroundColor: theme.palette.background.paper, 
              color: theme.palette.text.primary, 
              "& .MuiInputBase-input": { 
                backgroundColor: theme.palette.card.bg, 
                padding: "10px" 
              } 
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: theme.palette.text.primary }} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
          <div style={{ display: 'none' }}>
            <input type="text" id="PreventChromeAutocomplete"
              name="PreventChromeAutocomplete" autoComplete="address-level4" />
          </div>
        </div>

        <WeekPicker
          week={week}
          handlePreviousWeek={handlePreviousWeek}
          handleNextWeek={handleNextWeek}
          isMobile={true}
          handleWeekChange={handleWeekChange} />

        {/* Only show the primary action button in main view */}
        <Stack spacing={1} direction="row" justifyContent="space-between" sx={{ marginTop: "0.75rem" }}>
          {userInfo.isAdmin === true || (Object.keys(userInfo.permissionData).length !== 0 && userInfo.permissionData && userInfo.permissionData.Schedule.write) ? (
            loading ? (
              <Button
                variant="contained"
                style={{ textTransform: "none", fontWeight: "500", width: "100%" }}
                disabled={true}
              >
                <TailSpin color={theme.palette.primary.main} height={20} width={20} />
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{ textTransform: "none", fontWeight: "500", width: "100%" }}
                disabled={totalUnPublished === 0}
                onClick={handlePublishDialog}
              >
                {totalUnPublished > 0 ? `Publish (${totalUnPublished})` : "No Shifts"}
              </Button>
            )
          ) : null}
        </Stack>
      </Paper>

      {/* Side Drawer for secondary actions */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <div style={{ width: 280, padding: '1rem', height: '100%', backgroundColor: theme.palette.background.paper }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
            <Typography variant="h6">Actions</Typography>
            <IconButton onClick={toggleDrawer(false)}>
              &times;
            </IconButton>
          </div>
          
          <Divider style={{ marginBottom: '1rem' }} />
          
          <div style={{ marginBottom: "1rem" }}>
            <div style={{ fontWeight: "500", marginBottom: "0.5rem" }}>Sort by Department</div>
            <Select
              size="small"
              fullWidth
              value={department}
              onChange={handleSortByDepartment}
              sx={{
                borderRadius: 1,
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                "& .MuiSelect-icon": {
                  color: theme.palette.text.primary
                }
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: theme.palette.card.bg,
                    color: theme.palette.text.primary
                  }
                }
              }}
            >
              <MenuItem value="All">All</MenuItem>
              {summary.map((dept) => (
                <MenuItem key={dept.department} value={dept.department}>{dept.department}</MenuItem>
              ))}
            </Select>
          </div>

          <List>
            {userInfo.isAdmin === true || (Object.keys(userInfo.permissionData).length !== 0 && userInfo.permissionData && userInfo.permissionData.Schedule.write) ? (
              <>
                <ListItem 
                  button 
                  onClick={handleCopyScheduleDialog} 
                  disabled={totalUnPublished === 0 && totalPublished === 0}
                  sx={{ 
                    borderRadius: 1,
                    marginBottom: '0.5rem',
                    backgroundColor: theme.palette.action.hover
                  }}
                >
                  <ListItemIcon>
                    <ContentCopyIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Copy Schedule" />
                </ListItem>
                
                <ListItem 
                  button 
                  onClick={() => setDeleteDialog(true)} 
                  disabled={totalPublished === 0 && totalUnPublished === 0}
                  sx={{ 
                    borderRadius: 1,
                    marginBottom: '0.5rem',
                    backgroundColor: theme.palette.action.hover
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon sx={{ color: theme.palette.error.main }} />
                  </ListItemIcon>
                  <ListItemText primary="Delete All Shifts" />
                </ListItem>
                
                {/* <ListItem 
                  button 
                  onClick={clickPNG}
                  sx={{ 
                    borderRadius: 1,
                    marginBottom: '0.5rem',
                    backgroundColor: theme.palette.action.hover
                  }}
                >
                  <ListItemIcon>
                    <InfoIcon color="info" />
                  </ListItemIcon>
                  <ListItemText primary="Export Schedule as PNG" />
                </ListItem> */}
              </>
            ) : null}
          </List>
          
          <Divider style={{ margin: '1rem 0' }} />
          
          <div style={{ marginTop: '1rem' }}>
            <Typography variant="subtitle2" color="textSecondary">
              Week: {week}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {stringifyWeek(week)}
            </Typography>
          </div>
        </div>
      </Drawer>

      {/* Dialogs remain unchanged */}
      <Dialog open={deleteDialog}>
        <DialogTitle>Delete Shifts</DialogTitle>
        <DialogContent>
          Are you sure you want to delete all shifts?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteAll} style={{ color: "red" }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={PublishDialog}
        sx={{ maxWidth: "500px", margin: "0 auto" }}
        onClose={selectAllDepartments}
      >
        <DialogTitle id="alert-dialog-title">{"Publish Shifts"}</DialogTitle>

        <DialogContent>
          <div style={{ marginBottom: "1rem" }}>
            <div style={{ fontWeight: "500" }}>Select Departments to Publish
              <Button
                variant="contained"
                size="small"
                style={{ margin: "1rem", borderRadius: "15px" }}
                onClick={selectAllDepartments}
              >
                Select All
              </Button>
            </div>

            <Autocomplete
              multiple
              id="departments-select"
              value={selectedDepartments}
              onChange={(event, newValue) => setSelectedDepartments(newValue)}
              options={summary.map((dept) => dept.department)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                />
              )}
            />
          </div>
          <RadioGroup value={publishType} onChange={(e) => setPublishType(e.target.value)}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Radio value="unpublished" sx={{ color: theme.palette.text.primary }} />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ fontWeight: "500" }}>Publish Updates only</div>
                <div style={{ fontSize: "0.8rem", color: "#757575" }}>Publish only newly created or altered schedule. Only employees whose schedule have changed for selected departments will be notified.</div>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
              <Radio value="all" sx={{ color: theme.palette.text.primary }} />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ fontWeight: "500" }}>Publish All</div>
                <div style={{ fontSize: "0.8rem", color: "#757575" }}>Publish updates and re-publish existing schedule. All employees working this schedule for selected departments will be notified.</div>
              </div>
            </div>
          </RadioGroup>
        </DialogContent>
        {publishType &&
          <div>
            <Divider />
            <DialogTitle>Notifications</DialogTitle>
            <DialogContent>
              <RadioGroup value={toNotification} onChange={(e) => setToNotification(e.target.value)}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Radio value="true" sx={{ color: theme.palette.text.primary }}/>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontWeight: "500" }}>Email</div>
                    <div style={{ fontSize: "0.8rem", color: "#757575" }}>Staff will receive their shift schedule via email.</div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                  <Radio value="false" sx={{ color: theme.palette.text.primary }}/>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontWeight: "500" }}>Don't send notifications</div>
                  </div>
                </div>
              </RadioGroup>
            </DialogContent>
          </div>
        }
        <DialogActions>
          <Button color="primary" onClick={() => setPublishDialog(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={publishShifts} autoFocus>
            Publish
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={copyScheduleDialog}
        sx={{
          margin: "0 auto",
          width: "500px",
        }}
        onClose={selectAllDepartments}
      >
        <DialogTitle id="alert-dialog-title">{"Copy Schedule"}</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontWeight: "500" }}>Copy from</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="copy-from-select-label"
                  id="copy-from-select"
                  value={copyFrom}
                  style={{ width: "10rem" }}
                  onChange={(e) => setCopyFrom(e.target.value)}
                >
                  {copyOptions.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span style={{ backgroundColor: "#22baee", borderRadius: "10px", padding: "1px 5px", fontSize: "0.8rem", marginTop: "0.5rem", fontWeight: "600", width: "max-content", color: "#fff" }}>{stringifyWeek(copyFrom)}</span>
              <div style={{ fontWeight: "500", marginTop: "1rem" }}>Copy to</div>
              <div style={{ fontSize: "0.8rem", color: "#757575" }}>Select the schedule you want to copy to.</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="copy-to-select-label"
                  id="copy-to-select"
                  value={copyTo}
                  style={{ width: "10rem" }}
                  onChange={(e) => setCopyTo(e.target.value)}
                >
                  {copyOptions.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span style={{ backgroundColor: "#22baee", borderRadius: "10px", padding: "1px 5px", fontSize: "0.8rem", marginTop: "0.5rem", fontWeight: "600", width: "max-content", color: "#fff" }}>{stringifyWeek(copyTo)}</span>
              <div style={{ fontWeight: "500" }}>Select Departments to Repeat
                <Button
                  variant="contained"
                  size="small"
                  style={{ margin: "1rem", borderRadius: "15px" }}
                  onClick={selectAllDepartments}
                >
                  Select All
                </Button>
              </div>

              <Autocomplete
                multiple
                id="departments-select"
                value={selectedDepartments}
                onChange={(event, newValue) => setSelectedDepartments(newValue)}
                options={summary.map((dept) => dept.department)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setCopyScheduleDialog(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={copySchedule} autoFocus>
            Copy
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog 
        open={overLapDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Overlapping Shifts</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The following shifts overlap with the selected shifts. Please resolve the conflicts.
          </DialogContentText>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TableContainer 
              component={Paper} 
              sx={{ 
                borderRadius: 8, 
                overflow: 'auto', 
                maxHeight: '60vh',
                '&::-webkit-scrollbar': {
                  height: '8px',
                  width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#bdbdbd',
                  borderRadius: '4px',
                }
              }}
            >
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f4f4f4', minWidth: '80px' }}>Employee</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f4f4f4', minWidth: '80px' }}>Existing</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f4f4f4', minWidth: '80px' }}>New</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f4f4f4', minWidth: '50px' }}>Day</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f4f4f4', minWidth: '70px' }}>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {overLapData && overLapData.map(({ employeeName, shifts }) => (
                    shifts.map((shift, index) => (
                      <TableRow key={`${employeeName}-${index}`}>
                        {index === 0 && (
                          <TableCell 
                            sx={{ 
                              fontWeight: 'bold', 
                              backgroundColor: '#e0f7fa',
                              padding: '6px 8px',
                              fontSize: '0.8rem'
                            }} 
                            rowSpan={shifts.length}
                          >
                            {employeeName.split(' ')[0]} {/* Display only first name to save space */}
                          </TableCell>
                        )}
                        <TableCell sx={{ padding: '6px 8px', fontSize: '0.8rem' }}>{shift.existingShift}</TableCell>
                        <TableCell sx={{ padding: '6px 8px', fontSize: '0.8rem' }}>{shift.newShift}</TableCell>
                        <TableCell sx={{ padding: '6px 8px', fontSize: '0.8rem' }}>{shift.day.substring(0, 3)}</TableCell>
                        <TableCell sx={{ padding: '6px 8px', fontSize: '0.8rem' }}>
                          {new Date(shift.date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}
                        </TableCell>
                      </TableRow>
                    ))
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOverLapDialog(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
});

export default MobileScheduleHeader;
