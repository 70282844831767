import { CircularProgress, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";


const CallBack = () => {
    //get query params
    const theme = useTheme();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const path = params.get('path');
    const branchId = params.get('branchId');
    const userType = params.get('userType');
    // const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useHistory();
    const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    
    const switchBranch = (branch) => {
        window.scrollTo(0, 0);
        const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
            },
          };
        axios
          .get(
            `${process.env.REACT_APP_API_BACKEND}/api/branch/switch/${branch}`,
            config
          )
          .then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            setTimeout(() => {
                //go to the path with window.location instead of navigate.push
                window.location.href = path;
            }
            , 2000);
          })
          .catch((err) => {
            console.log(err.response.data.message);
          });
      };

    useEffect(() => {
        if (userInfo != null) {
            //set current branchId  to branchId
            if(userInfo.currentBranchId != branchId){
                switchBranch(branchId);
            }else{
                setTimeout(() => {
                    window.location.href = path;
                }
                , 3000);
            }
        }else{
            const redirectData = {
                path: path,
                branchId: branchId,
                userType: userType
            };
            sessionStorage.setItem('redirectData', JSON.stringify(redirectData));
            if(userType == 'cmsAdmin'){
                navigate.push('/user/login');
            }else{
                navigate.push('/login');
            }
        }
    }
    , []);





    
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: theme.palette.background.default}}>
                <CircularProgress />
        </div>
    );
    }


export default CallBack;
