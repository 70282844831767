import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Box from "@mui/material/Box";
import logo from "../crinitis-logo.png";
import { useLocation, useHistory, Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import { cmsUserLogin } from "../actions/userActions";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../components/ErrorAlert";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from "axios";
import { useTheme } from "@mui/material/styles";

export default function UserLogin() {
  const [value, setValue] = useState("/user/login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const history = useHistory();

  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  const dispatch = useDispatch();
  const redirect = location.search ? location.search.split("=")[1] : "/";
  const redirectData = sessionStorage.getItem("redirectData") ? JSON.parse(sessionStorage.getItem("redirectData")) : null;
  const path = redirectData ? redirectData.path : '/';
  const theme = useTheme();

  useEffect(() => {
    if (userInfo && userInfo.JWT_TOKEN) {
      if (redirectData) {
        sessionStorage.removeItem("redirectData");
        if (userInfo.currentBranchId != redirectData.branchId) {
          switchBranch(redirectData.branchId);
        } else
          history.push(redirectData.path);
      } else {
        history.push('/');
      }
    }
  }, [history, userInfo, redirect]);

  const switchBranch = (branch) => {
    window.scrollTo(0, 0);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BACKEND}/api/branch/switch/${branch}`,
        config
      )
      .then((res) => {
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        setTimeout(() => {
          //go to the path with window.location instead of navigate.push
          window.location.href = path;
        }
          , 2000);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage("Please provide an email");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    } else if (!password) {
      setErrorMessage("Please provide a password");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    } else {
      dispatch(cmsUserLogin(email.toLowerCase(), password));
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 5000);
      }
    }
  };
  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(/images/login-bg.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: 'hidden',
        overflowX: 'hidden'
      }}
    >
      <Card
        sx={{
          width: {
            xs: 'calc(100% - 32px)', // Full width minus padding on mobile
            sm: '400px'              // Fixed width on larger screens
          },
          m: 2,
          boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: "#ffffff8d",
          backdropFilter: "blur(10px)",
          maxHeight: {
            xs: 'calc(100vh - 32px)', // Full height minus padding on mobile
            sm: '650px'               // Increased max height
          }
        }}
      >
        <CardContent
          sx={{
            p: { xs: 2.5, sm: 3 }, // Increased padding
            '&:last-child': { pb: { xs: 2.5, sm: 3 } },
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            gap: 2 // Increased gap between direct children
          }}
        >
          {/* Logo and Title - more compact */}
          <Box sx={{ textAlign: 'center', mb: 1 }}>
            <img
              src="/images/empstar-black.png"
              alt="logo"
              style={{ 
                height: '55px',
                marginBottom: '16px',
              }}
            />
            <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
              Admin Login
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
              Please enter your admin credentials
            </Typography>
          </Box>

          {/* Error/Loading Display */}
          {(loading || (show && (error || errorMessage))) && (
            <Box sx={{ my: 1 }}>
              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size={24} />
                </Box>
              )}
              {show && (error || errorMessage) && <ErrorAlert error={error || errorMessage} />}
            </Box>
          )}

          {/* Admin login form */}
          <Box 
            component="form" 
            onSubmit={submitHandler}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2 // Consistent gap between form elements
            }}
          >
            <TextField
              fullWidth
              label="Email Address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              variant="outlined"
              size="medium" // Changed to medium
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon />
                  </InputAdornment>
                )
              }}
            />

            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              variant="outlined"
              size="medium" // Changed to medium
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                )
              }}
            />

            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              flexWrap: 'wrap',
              gap: 1,
              my: 1 // Added vertical margin
            }}>
              <Link
                to="/reset-password/user"
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: 'none',
                  fontSize: '0.875rem'
                }}
              >
                Forgot Password?
              </Link>
              <Link
                to="/login"
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: 'none',
                  fontSize: '0.875rem'
                }}
              >
                Employee Login
              </Link>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{
                py: 1.5,
                px: 3,
                fontSize: '1rem',
                fontWeight: 600,
                borderRadius: 1.5,
                textTransform: 'none',
                width: '100%',
                mt: 1
              }}
            >
              Sign In
              <ArrowForwardIosIcon sx={{ ml: 1.5, fontSize: '0.9rem' }} />
            </Button>
          </Box>

          {/* Footer - more compact */}
          <Box sx={{ 
            mt: 'auto', 
            pt: 3, // Increased top padding
            textAlign: 'center'
          }}>
            <Typography 
              sx={{ 
                color: theme.palette.text.primary,
                mb: 1,
              }}
            >
              Powered by
            </Typography>
            <img
              src="https://crinitis-ms-app-files.s3.ap-southeast-2.amazonaws.com/assets/crinitis-horizontal-black.png"
              style={{ 
                maxWidth: '125px',
                display: 'block',
                margin: '8px auto 0'
              }}
              alt="Powered by logo"
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
