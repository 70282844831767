import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, List, ListItem, ListItemText, Typography, Skeleton } from '@mui/material';
import { useTheme } from '@emotion/react';

const LocationSidebar = ({ onSelectLocation, onSetRadius, setErrorMessage }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [radius, setRadius] = useState(30); // Default radius
    const [loading, setLoading] = useState(false);

    const handleSearch = async () => {
        setLoading(true);
        try {
            const searchQuery = `https://nominatim.openstreetmap.org/search.php?q=${query}&format=jsonv2`;
            const response = await axios.get(searchQuery);
            setResults(response.data);
            setLoading(false);
            if (response.data.length === 0) {
                setErrorMessage('No results found, Try entering correct City name')
            }

        } catch (error) {
            setLoading(false);
            console.error('Error fetching location:', error);
        }
    };

    const handleLocationSelect = (location) => {
        onSelectLocation(location);
    };

    const handleRadiusChange = (e) => {
        const inputValue = e.target.value;
        // Validate input value to be a positive number
        if (inputValue === '' || (Number(inputValue) >= 0 && !isNaN(inputValue))) {
            setRadius(inputValue === '' ? '' : Number(inputValue));
            onSetRadius(inputValue === '' ? '' : Number(inputValue));
        }
    };
    const theme = useTheme();
    return (
        <div style={{ width: '300px', backgroundColor: theme.palette.card.bg, padding: '10px', borderRadius: '20px' }}>
            <Typography variant="h6" sx={{ marginBottom: '10px' }}>Location Search</Typography>
            <TextField
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                size='small'
                fullWidth
                label="Search City"
                variant="outlined"
            />
            <Button onClick={handleSearch} variant="contained" color="primary" style={{ marginTop: '10px' }} fullWidth size="small">
                Search
            </Button>

            <TextField
                type="number"
                value={radius}
                onChange={handleRadiusChange}
                size='small'
                fullWidth
                label="Radius (meters)"
                variant="outlined"
                style={{ marginTop: '10px' }}
                error={radius !== '' && (Number(radius) <= 0 || isNaN(radius))}
                helperText={radius !== '' && (Number(radius) <= 0 || isNaN(radius)) ? 'Please enter a valid positive number' : ''}
            />

            {loading ? (
                <List style={{ marginTop: '20px', maxHeight: '300px', overflowY: 'auto' }}>
                    <ListItem>
                        <Skeleton variant="rectangular" width="100%" height={50} sx={{ borderRadius: '20px', backgroundColor: theme.palette.card.alt }} />

                    </ListItem>
                    <ListItem>
                        <Skeleton variant="rectangular" width="100%" height={50} sx={{ borderRadius: '20px', backgroundColor: theme.palette.card.alt }} />
                    </ListItem>
                    <ListItem>
                        <Skeleton variant="rectangular" width="100%" height={50} sx={{ borderRadius: '20px', backgroundColor: theme.palette.card.alt }} />
                    </ListItem>
                </List>
            ) : (

                <List style={{ marginTop: '20px', maxHeight: '300px', overflowY: 'auto' }}>
                    {results.map((result) => (
                        <ListItem
                            key={result.place_id}
                            button
                            onClick={() => handleLocationSelect(result)}
                            style={{ marginBottom: '10px', backgroundColor: theme.palette.card.alt, borderRadius: '10px' }}
                        >
                            <ListItemText primary={result.display_name} />
                        </ListItem>
                    ))}
                </List>
            )}
        </div>
    );
};

export default LocationSidebar;
