import React, { useState, useEffect } from "react";
import { 
  Box, 
  Typography, 
  Button, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  IconButton,
  Skeleton,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Avatar,
  Grid,
  Divider,
  Tooltip
} from "@mui/material";
import { Delete, Edit, Add, PersonOutline } from "@mui/icons-material";
import axios from "axios";
import { useSelector } from "react-redux";
import ReactQuill from 'react-quill'; // Add this import
import 'react-quill/dist/quill.snow.css'; // Add this import for Quill styles

const EmployeeNotes = ({ id }) => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [noteContent, setNoteContent] = useState("");
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  
  useEffect(() => {
    fetchNotes();
  }, [id]);

  const fetchNotes = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
      };
      const { data } = await axios.get(`${process.env.REACT_APP_API_BACKEND}/api/employee/${id}/notes`, config);
      // Handle API response structure
      const notesList = data.success && data.notes ? data.notes : [];
      setNotes(Array.isArray(notesList) ? notesList : []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching notes:", error);
      showSnackbar("Failed to load notes", "error");
      setNotes([]);
      setLoading(false);
    }
  };

  const handleAddNote = async () => {
    if (!noteContent || noteContent.trim() === "") {
      showSnackbar("Note content cannot be empty", "error");
      return;
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/${id}/notes`, 
        { note: noteContent }, 
        config
      );

      if (data.success) {
        showSnackbar("Note added successfully", "success");
        setOpenAddDialog(false);
        resetForm();
        fetchNotes();
      } else {
        showSnackbar(data.message || "Failed to add note", "error");
      }
    } catch (error) {
      console.error("Error adding note:", error);
      showSnackbar(error.response?.data?.message || "Failed to add note", "error");
    }
  };

  const handleEditNote = async () => {
    if (!noteContent || noteContent.trim() === "") {
      showSnackbar("Note content cannot be empty", "error");
      return;
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
      };

      const { data } = await axios.patch(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/notes/${selectedNote.id}`,
        { note: noteContent },
        config
      );
      
      if (data.success) {
        showSnackbar("Note updated successfully", "success");
        setOpenEditDialog(false);
        resetForm();
        fetchNotes();
      } else {
        showSnackbar(data.message || "Failed to update note", "error");
      }
    } catch (error) {
      console.error("Error updating note:", error);
      showSnackbar(error.response?.data?.message || "Failed to update note", "error");
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_BACKEND}/api/employee/notes/${noteId}`, 
        config
      );
      
      if (data.success) {
        showSnackbar("Note deleted successfully", "success");
        fetchNotes();
      } else {
        showSnackbar(data.message || "Failed to delete note", "error");
      }
    } catch (error) {
      console.error("Error deleting note:", error);
      showSnackbar(error.response?.data?.message || "Failed to delete note", "error");
    }
  };

  const openAddModal = () => {
    resetForm();
    setOpenAddDialog(true);
  };

  const openEditModal = (note) => {
    setSelectedNote(note);
    setNoteContent(note.note);
    setOpenEditDialog(true);
  };

  const openDeleteConfirmation = (note) => {
    setNoteToDelete(note);
    setOpenDeleteDialog(true);
  };

  const confirmDelete = () => {
    if (noteToDelete) {
      handleDeleteNote(noteToDelete.id);
      setOpenDeleteDialog(false);
      setNoteToDelete(null);
    }
  };

  const cancelDelete = () => {
    setOpenDeleteDialog(false);
    setNoteToDelete(null);
  };

  const resetForm = () => {
    setNoteContent("");
    setSelectedNote(null);
  };

  const handleDialogClose = () => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    resetForm();
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    // return new Date(dateString).toLocaleString();
   // 11/02/2025 03:51 pm
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  };

  const canAddNotes = () => {
    return userInfo.isAdmin === true || (Object.keys(userInfo.permissionData).length !== 0 && userInfo.permissionData.Employee.write);
  };

  const canModifyNote = (note) => {
    if (!note) return false;
    
    // Admins or users with Employee write permission can modify all notes
    if (userInfo.isAdmin === true || (Object.keys(userInfo.permissionData).length !== 0 && userInfo.permissionData.Employee.write)) {
      return true;
    }

    // Otherwise, no modification allowed
    return false;
  };

  // Skeleton loader component for notes
  const NotesSkeleton = () => (
    <>
      {[1, 2, 3].map((item) => (
        <Card key={item} sx={{ mb: 2 }}>
          <CardContent>
            <Box display="flex" alignItems="center" mb={1}>
              <Skeleton variant="circular" width={40} height={40} />
              <Box ml={2}>
                <Skeleton width={120} height={24} />
                <Skeleton width={80} height={20} />
              </Box>
            </Box>
            <Skeleton height={60} />
          </CardContent>
        </Card>
      ))}
    </>
  );

  // Determine creator type icon/color
  const getCreatorAvatar = (note) => {
    if (!note) return <PersonOutline />;
    
    if (note.creatorType === "CMSuser") {
      return {
        icon: <PersonOutline />,
        bgColor: 'primary.main'
      };
    } else {
      return {
        icon: <PersonOutline />,
        bgColor: 'secondary.main'
      };
    }
  };

  // Quill editor modules configuration
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
  };
  
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
  ];

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt={2}>
        <Typography variant="h5" component="h2" color="text.primary">
          Employee Notes
        </Typography>
        {canAddNotes() && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={openAddModal}
            sx={{
              boxShadow: 2,
              '&:hover': { transform: 'translateY(-1px)' },
              transition: 'transform 0.2s'
            }}
          >
            Add Note
          </Button>
        )}
      </Box>

      {loading ? (
        <NotesSkeleton />
      ) : !Array.isArray(notes) || notes.length === 0 ? (
        <Paper 
          sx={{ 
            p: 3, 
            textAlign: 'center',
            borderRadius: 2,
            backgroundColor: 'background.paper',
            boxShadow: 1
          }}
        >
          <Typography variant="body1" color="text.primary">No notes found for this employee.</Typography>
        </Paper>
      ) : (
        <Grid container spacing={2}>
          {notes.map((note) => (
            <Grid item xs={12} key={note.id}>
              <Card 
                sx={{ 
                  position: 'relative',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: 3
                  },
                  overflow: 'visible'
                }}
              >
                <CardContent>
                  <Box 
                    sx={{ 
                      mb: 2,
                      // Improved custom styling for the rich text content with reduced paragraph spacing
                      '& p': { marginBottom: '0.2em', marginTop: '0.2em', lineHeight: '24px' },
                      '& h1, & h2, & h3, & h4, & h5, & h6': { marginBottom: '0.3em', marginTop: '0.3em' },
                      '& ul, & ol': { marginBottom: '0.3em', marginTop: '0.3em', paddingLeft: '1.5em' },
                      '& li': { marginBottom: '0.1em' },
                      '& blockquote': { marginBottom: '0.3em', marginTop: '0.3em' },
                      '& .ql-indent-1': { paddingLeft: '3em' },
                      '& .ql-indent-2': { paddingLeft: '6em' },
                      // Fix for paragraph spacing
                      '& p + p': { marginTop: '0.4em' }, // When paragraphs follow each other
                      '& br': { lineHeight: 0.8 }, // Reduce line height for line breaks
                      '& div': { lineHeight: 0.2 }, // Restore line height for divs
                      fontSize: '1rem',
                      lineHeight: 1.2, // Slightly increased from 1 for better readability
                    }}
                    dangerouslySetInnerHTML={{ __html: note.note }}
                  />
                  
                  <Box 
                    sx={{ 
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                      mt: 2,
                      pt: 2,
                      borderTop: '1px solid',
                      borderColor: 'divider'
                    }}
                  >
                    <Box>
                      <Typography 
                        variant="caption" 
                        color="text.primary" 
                        sx={{ 
                          display: 'block',
                          mb: 0.5,
                          fontSize: '0.7rem'
                        }}
                      >
                        <Box component="span" sx={{ fontWeight: 400 }}>By:</Box> {note.creatorName || 'Unknown'} 
                      </Typography>
                      <Typography 
                        variant="caption" 
                        color="text.primary"
                        sx={{ 
                          display: 'block',
                          fontSize: '0.7rem'
                        }}
                      >
                        <Box component="span" sx={{ fontWeight: 400 }}>Created:</Box> {formatDate(note.createdAt)}
                        {note.updatedAt && note.updatedAt !== note.createdAt && (
                          <Box component="span" sx={{ ml: 2 }}>
                            <Box component="span" sx={{ fontWeight: 500 }}>Edited:</Box> {formatDate(note.updatedAt)}
                          </Box>
                        )}
                      </Typography>
                    </Box>

                    {canModifyNote(note) && (
                      <Box sx={{  }}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => openEditModal(note)}
                          sx={{ 
                            mr: 1,
                            '&:hover': { 
                              backgroundColor: 'primary.main',
                              color: 'primary.contrastText'
                            }
                          }}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => openDeleteConfirmation(note)}
                          sx={{ 
                            '&:hover': { 
                              backgroundColor: 'error.main',
                              color: 'error.contrastText'
                            }
                          }}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Add Note Dialog */}
      <Dialog 
        open={openAddDialog} 
        onClose={handleDialogClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: 3
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>Add Note</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, '& .ql-container': { height: '200px' } }}>
            <ReactQuill
              theme="snow"
              value={noteContent}
              onChange={setNoteContent}
              modules={modules}
              formats={formats}
              placeholder="Write your note here..."
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={handleDialogClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleAddNote} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Note Dialog */}
      <Dialog 
        open={openEditDialog} 
        onClose={handleDialogClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: 3
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>Edit Note</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, '& .ql-container': { height: '200px' } }}>
            <ReactQuill
              theme="snow"
              value={noteContent}
              onChange={setNoteContent}
              modules={modules}
              formats={formats}
              placeholder="Edit your note here..."
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={handleDialogClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleEditNote} variant="contained" color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={cancelDelete}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: 3
          }
        }}
      >
        <DialogTitle id="delete-dialog-title" sx={{ pb: 1 }}>
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this note? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={cancelDelete} color="inherit">
            Cancel
          </Button>
          <Button onClick={confirmDelete} variant="contained" color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%', boxShadow: 2 }}
          elevation={6}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmployeeNotes;