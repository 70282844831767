import React, { useState } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InformationTab from "../../My Company/InformationTab";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import AllBranchesTab from "./AllBranchesTab";
import CreateNewBranchTab from "./CreateNewBranchTab";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  const history = useHistory();
  const location = useLocation();
//   useEffect(() => {
  
//     if(userInfo.flag == 'Registered'){
//       history.push('/onboard')
//     }
//     if(userInfo.flag == 'Onboarding'){
//       history.push('/registered')
//     }
// }, [userInfo])
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      sx: { color: "text.primary" },
    };
  }

function Branches() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  return <div>
       <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{ px: 5, textTransform: "capitalize"}}
              label="All Branches"
              {...a11yProps(0)}
            />
            {userInfo.isAdmin && (
            <Tab
              sx={{ px: 5, textTransform: "capitalize" }}
              label="Create New Branch"
              {...a11yProps(1)}
            />
            )}
           </Tabs>
           
        </Box>
        <TabPanel component={Box} value={value} index={0}>
          <AllBranchesTab />
        </TabPanel>
        <TabPanel component={Box} value={value} index={1}>
          <CreateNewBranchTab />
        </TabPanel>
      </Box>
  </div>;
}

export default Branches;
