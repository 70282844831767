import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, useTheme, useMediaQuery } from '@mui/material';
import RoleSettings from './RoleSetting';
import DepartmentSettings from './DepartmentSettings';
import GeoTagging from './GeoTagging/GeoTagging';

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div
      hidden={value !== index}
      style={{
        height: "calc(100vh - 180px)",
        overflow: "auto"
      }}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
};

const GeneralSettings = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      height: '100%',
      maxWidth: '100%',
      overflow: 'hidden'
    }}>
      <div style={{ 
        flex: isMobile ? '1' : '0 0 auto', 
        width: isMobile ? '100%' : 'max-content',
        minWidth: isMobile ? 'auto' : '220px',
        margin: isMobile ? "0 0 20px 0" : "0 20px 0 0" 
      }}>
        <h1 style={{ 
          textAlign: isMobile ? 'center' : 'left',
          fontSize: isSmall ? '1.5rem' : '2rem', 
          marginBottom: '15px'
        }}>Settings</h1>
        <Box sx={{
          borderRadius: '10px',
          margin: '0 auto',
          width: isMobile ? '100%' : 'auto',
          boxSizing: 'border-box'
        }}>
          <Tabs
            orientation={isMobile ? 'horizontal' : 'vertical'}
            variant="scrollable"
            scrollButtons={isMobile ? "auto" : false}
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            sx={{
              width: '100%',
              '.MuiTabs-flexContainer': {
                justifyContent: isMobile ? 'center' : 'flex-start',
              },

            }}
          >
            <Tab
              label="Departments"
              sx={{
                color: theme.palette.text.primary,
                fontSize: isSmall ? '1rem' : '1.2rem',
                fontWeight: 600,
                marginLeft: isMobile ? '100px' : '0',
                minWidth: isMobile ? '120px' : '200px',
                padding: isSmall ? '6px 12px' : '12px 16px',
              }}
            />
            <Tab
              label="Roles"
              sx={{
                color: theme.palette.text.primary,
                fontSize: isSmall ? '1rem' : '1.2rem',
                fontWeight: 600,
                minWidth: isMobile ? '120px' : '200px',
                padding: isSmall ? '6px 12px' : '12px 16px',
              }}
            />
            <Tab
              label="Geo Tagging"
              sx={{
                color: theme.palette.text.primary,
                fontSize: isSmall ? '1rem' : '1.2rem',
                fontWeight: 600,
                minWidth: isMobile ? '120px' : '200px',
                padding: isSmall ? '6px 12px' : '12px 16px',
              }}
            />
          </Tabs>
        </Box>
      </div>
      <div style={{ 
        flex: '1',
        overflow: 'auto',
        maxWidth: '100%',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '8px',
        padding: isMobile ? '10px 0' : '15px 0'
      }}>
        <TabPanel value={value} index={0}>
          <DepartmentSettings />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RoleSettings />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <GeoTagging />
        </TabPanel>
      </div>
    </div>
  );
};

export default GeneralSettings;