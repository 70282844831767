import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Button, useTheme } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InviteEmployee from "./InviteEmployee";


//custom style for input text field
const styles = (theme) => ({
    input: {
        height: 40,
        paddingRight: 0,
    },
});

const MobileEmployeeHeader = withStyles(styles)((props) => {
    const { classes } = props;
    const [status, setStatus] = useState(() => sessionStorage.getItem('employeeStatus') || "Active");
    const [search, setSearch] = useState("");
    const [allEmployees, setAllEmployees] = useState([]);
    const theme = useTheme();
    const handleChange = (event) => {
        const newStatus = event.target.value;
        setStatus(newStatus);
        sessionStorage.setItem('employeeStatus', newStatus);
        //set employess to filter by status
        if (newStatus === "All") {
            props.setEmployees(props.mainEmployees);
        }
        else {
            props.setEmployees(props.mainEmployees.filter(employee => employee.flag && employee.flag.flag === newStatus));
        }
    };
    const getActiveEmployeesCount = (employees) => {
        let count = 0;
        employees.forEach((employee) => {
            if (employee.flag && employee.flag.flag === "Active") {
                count++;
            }
        });
        return count;
    }

    React.useEffect(() => {
        const savedStatus = sessionStorage.getItem('employeeStatus') || "Active";
        // Initial filtering based on saved status
        if (savedStatus === "All") {
            props.setEmployees(props.mainEmployees);
        } else {
            props.setEmployees(props.mainEmployees.filter(employee => 
                employee.flag && employee.flag.flag === savedStatus
            ));
        }
    }, [props.mainEmployees]);

    //search function
    const searchHandler = (e) => {
        setSearch(e.target.value);
        props.setEmployeeLoading(true);
        if (e.target.value !== "") {
            let searchedEmployees = props.mainEmployees;
            searchedEmployees = searchedEmployees.filter(function (currentElement) {
                return (
                    (currentElement.email &&
                        currentElement.email
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())) ||
                    (currentElement.employeeDetail.fname &&
                        currentElement.employeeDetail.fname
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())) ||
                    (currentElement.employeeDetail.lname &&
                        currentElement.employeeDetail.lname
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase()))
                );
            });
            props.setEmployeeLoading(false);
            props.setEmployees(searchedEmployees);
        } else {
            props.setEmployeeLoading(false);
            props.setEmployees(props.mainEmployees);
        }
    };

    return (
        <div className="header"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
            {/* <div
       
      >
        <div className="left-container">
          
          <div style={{ fontSize: "1.4rem", marginBottom: "5px" }}>Employees 
          {
          <span style={{ fontSize: "1rem", fontWeight: "600", color: "#fff", backgroundColor:"green", padding:"0.2rem 0.5rem", borderRadius:"5px", marginLeft:"0.5rem" }}>
            {getActiveEmployeesCount(props.mainEmployees)} Active
          </span>
          }
          </div> 
          
          <br />
          <span>Search by name/email</span>
          <br />
          <TextField
            id="outlined-basic"
            style={{ width: "550px" }}
            sx={{ borderRadius: 1, backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary, "& .MuiInputBase-input": { backgroundColor: theme.palette.card.bg, padding: "10px"} }}
            value={search}
            onChange={searchHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: theme.palette.text.primary }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0 5px 5px 0",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      position: "absolute",
                      right: "0",
                      height: "100%"
                    }}
                    onClick={searchHandler}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </div>
        <div className="right-container">
         
         {props.userInfo.isAdmin || props.userInfo.permissionData.Employee.read || props.userInfo.permissionData.Employee.write  ?
          <InviteEmployee fetchEmployees={props.fetchEmployees} />
          : null
          }
          <div style={{ marginTop: "1.2rem" }}>Status</div>
          <FormControl fullWidth size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              onChange={handleChange}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Active"}>Active</MenuItem>
              <MenuItem value={"Inactive"}>Inactive</MenuItem>
              <MenuItem value={"Onboarding"}>Onboarding</MenuItem>
              <MenuItem value={"Email Sent"}>Email Sent</MenuItem>
              <MenuItem value={"Registered"}>Registered</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div> */}
            <div style={{ fontSize: "1.4rem", marginBottom: "5px" }}>
                Employees
                
                    <span style={{ fontSize: "1rem", fontWeight: "600", color: "#fff", backgroundColor: "green", padding: "0.2rem 0.5rem", borderRadius: "5px", marginLeft: "0.5rem" }}>
                        {getActiveEmployeesCount(props.mainEmployees)} Active
                    </span>
          
            </div>
            <div style={{ marginTop: "0.5rem" }}>
                <TextField
                    id="outlined-basic"
                    style={{ width: "100%" }}
                    sx={{ borderRadius: 1, backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary, "& .MuiInputBase-input": { backgroundColor: theme.palette.card.bg, padding: "10px" } }}
                    value={search}
                    onChange={searchHandler}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: theme.palette.text.primary }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    variant="contained"
                                    style={{
                                        borderRadius: "0 5px 5px 0",
                                        textTransform: "capitalize",
                                        fontWeight: "600",
                                        position: "absolute",
                                        right: "0",
                                        height: "100%"
                                    }}
                                    onClick={searchHandler}
                                >
                                    Search
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                />

            </div>
            <div style={{ marginTop: "0.5rem", display: "flex", justifyContent: "space-between", padding: "0 10px" }}>
       
                <div>
                    <FormControl fullWidth size="small">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            onChange={handleChange}
                        >
                            <MenuItem value={"All"}>All</MenuItem>
                            <MenuItem value={"Active"}>Active</MenuItem>
                            <MenuItem value={"Inactive"}>Inactive</MenuItem>
                            <MenuItem value={"Onboarding"}>Onboarding</MenuItem>
                            <MenuItem value={"Email Sent"}>Email Sent</MenuItem>
                            <MenuItem value={"Registered"}>Registered</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    {props.userInfo.isAdmin || props.userInfo.permissionData.Employee.read || props.userInfo.permissionData.Employee.write ?
                        <InviteEmployee fetchEmployees={props.fetchEmployees} />
                        : null
                    }

                </div>
            </div>

        </div>
    );
});

export default MobileEmployeeHeader;
