import * as React from "react";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link } from "react-router-dom";
import ShiftTimeLine from "./ShiftTimeLine";
import { Avatar, Button, Chip, TextField, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TimelapseOutlined from "@mui/icons-material/TimelapseOutlined";
import { CalendarIcon } from "@mui/x-date-pickers";
import Tooltip from "@mui/material/Tooltip";

export default function ViewOnlyShiftBox(props) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullData = { employeeDetail: props.employee, email: props.email };
    const { schedule } = props.shift;
    const [startvalue, setStartValue] = useState(new Date(props.shift.startDate));
    const [endvalue, setEndValue] = useState(
        !props.shift.endDate ? new Date() : new Date(props.shift.endDate)
    );
    const [breakTime, setBrakeTime] = useState(
        Array.isArray(props.shift.break) ? props.shift.break : []
    );
    const [shiftApproved, setShiftApproved] = useState(props.shift.approved);
    const [shiftLength, setShiftLength] = useState(props.shift.totalShiftLength);
    const [totalBreak, setTotalBreak] = useState(props.shift.totalBreak);
    const [shiftWOBreak, setShiftWOBreak] = useState(
        props.shift.shiftWithoutBreak
    );

    function convertS3ToCloudFront(url) {
        // Replace the AWS S3 domain with the CloudFront domain
        const cloudFrontDomain = 'https://d2k8ghk99lpcqm.cloudfront.net/';
        const s3Domain = 'https://cms-shifts.s3.ap-southeast-2.amazonaws.com/';
        
        // Check if the URL starts with the S3 domain
        if (url && url.startsWith(s3Domain)) {
          // Replace the S3 domain with the CloudFront domain
          const convertedUrl = url.replace(s3Domain, cloudFrontDomain);
          return convertedUrl;
        } else {
          // URL doesn't match the expected pattern
          console.error('Invalid URL format');
          return url;
        }
    }

    const scheduleContent = schedule ? (
        <Box
          sx={{
            fontSize: "0.85rem",
            color: "#fff",
            backdropFilter: "blur(2px)", // Glass effect with blur
            borderRadius: "12px", // Rounded corners for the box
            padding: "15px",
            maxWidth: "300px",
            lineHeight: "1.5",
            fontFamily: "Roboto, sans-serif",
            
          }}
        >
          <div><strong>Date:</strong> {new Date(schedule.date).toLocaleDateString()}</div>
          <div><strong>Day:</strong> {schedule.day}</div>
          <div><strong>Start Time:</strong> {schedule.startTime}</div>
          <div><strong>End Time:</strong> {schedule.endTime}</div>
          <div><strong>Total Hours:</strong> {schedule.totalHours}</div>
          <div><strong>Unpaid Break:</strong> {schedule.unpaidBreak}</div>
          <div><strong>Paid Break:</strong> {schedule.paidBreak}</div>
          <div><strong>Notes:</strong> {schedule.notes || "N/A"}</div>
          <div><strong>Total Shift Length:</strong> {schedule.totalShiftLength}</div>
        </Box>
      ) : null;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    };

    const {
        startImage,
        endImage,
        startDate,
        endDate,
        shiftWithoutBreak,
        totalShiftLength,
        approved
    } = props.shift;

    const subtractTime = (time1, time2) => {
        //smaple 2023-08-11T08:54:19.659Z, 2023-08-11T09:31:42.968Z
        const date1 = new Date(time1);
        const date2 = new Date(time2);
        const diff = date2.getTime() - date1.getTime();
        //round to hh mm
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);
        //return hh:mm
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    };

    function calculateTotalBreak() {
        let hours = 0;
        let minutes = 0;
        let seconds = 0;
        breakTime.map((brk) => {
            let now = new Date(brk.start);
            let endDate = brk.end ? new Date(brk.end) : new Date();
            let diff = Math.abs(endDate - now);

            hours = hours + Math.floor(diff / 3.6e6);
            minutes = minutes + Math.floor((diff % 3.6e6) / 6e4);
            if (minutes > 59) {
                minutes = minutes - 60;
                hours = hours + 1;
            }
            seconds = seconds + Math.round(Math.floor((diff % 6e4) / 1000));
        });
        let total;
        if (hours < 10) {
            if (minutes < 10) {
                if (seconds < 10) {
                    total = "0" + hours + ":0" + minutes + ":0" + seconds;
                } else {
                    total = "0" + hours + ":0" + minutes + ":" + seconds;
                }
            } else {
                if (seconds < 10) {
                    total = "0" + hours + ":" + minutes + ":0" + seconds;
                } else {
                    total = "0" + hours + ":" + minutes + ":" + seconds;
                }
            }
        } else {
            if (minutes < 10) {
                if (seconds < 10) {
                    total = hours + ":0" + minutes + ":0" + seconds;
                } else {
                    total = hours + ":0" + minutes + ":" + seconds;
                }
            } else {
                if (seconds < 10) {
                    total = hours + ":" + minutes + ":0" + seconds;
                } else {
                    total = hours + ":" + minutes + ":" + seconds;
                }
            }
        }

        setTotalBreak(total);
    }

    function calculateTotalShiftLength() {
        let now = new Date(startvalue);
        let endDate = new Date(endvalue);
        let diff = Math.abs(endDate - now);

        let hours = Math.floor(diff / 3.6e6);
        let minutes = Math.floor((diff % 3.6e6) / 6e4);
        let seconds = Math.round(Math.floor((diff % 6e4) / 1000));
        let total;
        if (hours < 10) {
            if (minutes < 10) {
                if (seconds < 10) {
                    total = "0" + hours + ":0" + minutes + ":0" + seconds;
                } else {
                    total = "0" + hours + ":0" + minutes + ":" + seconds;
                }
            } else {
                if (seconds < 10) {
                    total = "0" + hours + ":" + minutes + ":0" + seconds;
                } else {
                    total = "0" + hours + ":" + minutes + ":" + seconds;
                }
            }
        } else {
            if (minutes < 10) {
                if (seconds < 10) {
                    total = hours + ":0" + minutes + ":0" + seconds;
                } else {
                    total = hours + ":0" + minutes + ":" + seconds;
                }
            } else {
                if (seconds < 10) {
                    total = hours + ":" + minutes + ":0" + seconds;
                } else {
                    total = hours + ":" + minutes + ":" + seconds;
                }
            }
        }

        setShiftLength(total);
    }

    const calculateshiftWithoutBreak = () => {
        let hours;
        let minutes;
        let seconds;

        const totalBreakHours = parseInt(totalBreak.slice(0, 2));
        const totalBreakMinutes = parseInt(totalBreak.slice(3, 5));
        const totalBreakSeconds = parseInt(totalBreak.slice(6, 8));

        const shiftLengthHours = parseInt(shiftLength.slice(0, 2));
        const shiftLengthMinutes = parseInt(shiftLength.slice(3, 5));
        const shiftLengthSeconds = parseInt(shiftLength.slice(6, 8));

        hours = shiftLengthHours - totalBreakHours;
        minutes = shiftLengthMinutes - totalBreakMinutes;
        seconds = shiftLengthSeconds - totalBreakSeconds;

        // Handle negative values
        if (seconds < 0) {
            minutes -= 1;
            seconds += 60;
        }
        if (minutes < 0) {
            hours -= 1;
            minutes += 60;
        }

        // Ensure that hours, minutes, and seconds are always two digits
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        const total = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        setShiftWOBreak(total);
    }

    const getAvatarName = (employee) => {
        if (employee.employeeDetail) {
            if (employee.employeeDetail.fname && employee.employeeDetail.lname) {
                return employee.employeeDetail.fname.charAt(0).toUpperCase() + employee.employeeDetail.lname.charAt(0).toUpperCase();
            }
            else if (employee.employeeDetail.fname) {
                return employee.employeeDetail.fname.charAt(0).toUpperCase();
            }
            else if (employee.employeeDetail.lname) {
                return employee.employeeDetail.lname.charAt(0).toUpperCase();
            }
        }
        else {
            return employee.email.charAt(0).toUpperCase();
        }
    }

    useEffect(() => {
        calculateTotalBreak();
        calculateTotalShiftLength();
        calculateshiftWithoutBreak();
    }, [
        startvalue,
        endvalue,
        breakTime,
        totalBreak,
        shiftWOBreak,
        shiftLength,
        calculateshiftWithoutBreak,
    ]);


    return (
        <>
            <Box sx={{ border: `2px solid ${shiftApproved ? theme.palette.success.main : theme.palette.info.main}`, width: "180px", paddingBottom: "10px", borderRadius: "20px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", textAlign: "center", cursor: "pointer", backgroundColor: theme.palette.background.paper, transition: "background-color 0.3s ease", margin: "auto", marginTop: "5px" }} onClick={handleClickOpen}>
                <Box sx={{
                    fontWeight: "500",
                    backgroundColor: shiftApproved ? theme.palette.success.main : theme.palette.info.main,
                    color: theme.palette.text.secondary, borderRadius: "10px", width: "90%", margin: "10px 2px 2px 2px", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center"
                }}>
                    <Box sx={{ padding: "2px 0" }}>
                        {formatTime(startDate).slice(0, 5)} -{" "}
                        {endDate ? formatTime(endDate).slice(0, 5)
                            :
                            <span>Active</span>}
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex", flexDirection: "row",
                    justifyContent: "space-between", alignItems: "center",
                    textAlign: "center",
                    fontSize: "1rem",
                    padding: "0",
                    fontWeight: "300",
                }}>
                    <Box sx={{ marginTop: "5px" }}>
                        Shift:{" "} <span>{shiftLength && shiftLength.slice(0, 5)}</span>
                    </Box>
                    <Box sx={{ marginRight: "5px", marginLeft: "5px" }}>
                        |
                    </Box>
                    <Box sx={{ marginTop: "5px" }}>
                        Break: <span>{totalBreak && totalBreak.slice(0, 5)}</span>
                    </Box>
                </Box>
                <Box sx={{ fontSize: "1rem", fontWeight: "300", marginTop: "5px" }} >
                    Shift w/o break:{" "}
                    <span
                        style={{ color: "#ee5622", fontWeight: "600" }}
                    >{shiftWOBreak && shiftWOBreak.slice(0, 5)} </span>
                </Box>
            </Box>
            
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    minWidth: "600px",
                    overflowY: "auto",
                    width: "max-content",
                    padding: "0",
                  },
                }}
            >
                <DialogContent style={{ display: "flex", padding: "0" }}>
                    <div
                        style={{
                            padding: "20px",
                            backgroundColor: theme.palette.background.default,
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                            borderRadius: "20px",
                            position: "sticky",
                            top: "0",
                            zIndex: "100",
                            minWidth: "150px",
                            color: theme.palette.text.primary,
                        }}>
                        <div style={{ margin: "0 auto" }}>
                            <Avatar style={{
                                width: "5rem", height: "5rem", color: "#fff", fontWeight: "500", fontSize: "2rem",
                                borderRadius: "20%"
                            }}
                            >
                                {getAvatarName(fullData)}
                            </Avatar>
                        </div>
                        <Link
                            style={{ textDecoration: "none", color: theme.palette.text.primary, margin: "0 auto" }}
                            to={`/employee/${props.employeeId}`}
                        >
                            <div>
                                <Typography style={{ fontWeight: "500", textAlign: "center", fontSize: "1.5rem", textTransform: "capitalize", cursor: "pointer", margin: "0 auto" }}>
                                    {props.employee.fname} {props.employee.lname}
                                </Typography>
                            </div>
                        </Link>

                        <Grid container spacing={1} style={{ marginTop: "10px" }}>
                            <Grid item xs={3} md={3}>
                                <AccessTimeIcon />
                            </Grid>
                            <Grid item xs={9} md={9}>
                                <span style={{ fontWeight: "600", textAlign: "center" }}>
                                    {shiftLength.slice(0, 5)}
                                </span>
                                <br />
                                <span style={{ fontSize: "0.8rem", textAlign: "center" }}>
                                    Shift length
                                </span>
                                <br />
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={1} style={{ marginTop: "10px" }}>
                            <Grid item xs={3} md={3}>
                                <FormatListBulletedIcon />
                            </Grid>
                            <Grid item xs={9} md={9}>
                                <span style={{ fontWeight: "600", textAlign: "center" }}>
                                    {totalBreak.slice(0, 5)}
                                </span>
                                <br />
                                <span style={{ fontSize: "0.8rem", textAlign: "center" }}>
                                    Total Break
                                </span>
                                <br />
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={1} style={{ marginTop: "10px" }}>
                            <Grid item xs={3} md={3}>
                                <TimelapseOutlined />
                            </Grid>
                            <Grid item xs={9} md={9}>
                                <span style={{ fontWeight: "600", textAlign: "center", color: "#ee5622" }}>
                                    {shiftWOBreak.slice(0, 5)}
                                </span>
                                <br />
                                <span style={{ fontSize: "0.8rem", textAlign: "center" }}>
                                    Shift w/o break
                                </span>
                                <br />
                            </Grid>
                        </Grid>
                        
                        <div style={{ marginTop: "15px" }}>
                            {schedule && (
                                <Tooltip
                                    title={<div>{scheduleContent}</div>}
                                    arrow
                                    placement="top"
                                    PopperProps={{
                                        modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                            offset: [0, 8],
                                            },
                                        },
                                        ],
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        style={{ 
                                        margin: "0 auto", 
                                        display: "block", 
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.text.secondary,
                                        textTransform: "none",
                                        fontWeight: "500",
                                        fontSize: "1rem",
                                        padding: "5px 10px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex"
                                        }}
                                    >
                                        <CalendarIcon sx={{marginRight: "2px", fontSize: "1rem"}} />
                                        Schedule
                                    </Button>
                                </Tooltip>
                            )}
                        </div>

                        <Typography style={{ position: "absolute", left: "15px", bottom: "20px", fontWeight: "600", fontSize: "1rem", marginRight: "5px" }}>
                            Shift ID: #{props.shift.id}
                        </Typography>
                    </div>

                    <div style={{ padding: "20px", fontSize: "20px" }}>
                        <Typography variant="h5"><strong>Shift Details - {new Date(props.shift.startDate).toLocaleDateString()}</strong></Typography>
                        {!props.shift.endDate && <Typography style={{ color: "#ee5622", fontWeight: "500" }}>Currently Active</Typography>}
                        
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid item xs={6}>
                                <Typography variant="body1" style={{ fontWeight: "600" }}>
                                    Start Time: <span style={{ backgroundColor: "#ee5622", color: "white", fontWeight: "600", padding: "2px 5px", borderRadius: "5px" }}>
                                        {formatTime(props.shift.startDate).slice(0, 5)}
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" style={{ fontWeight: "600" }}>
                                    End Time: <span style={{ backgroundColor: "#ee5622", color: "white", fontWeight: "600", padding: "2px 5px", borderRadius: "5px" }}>
                                        {props.shift.endDate ? formatTime(props.shift.endDate).slice(0, 5) : "Active"}
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <img
                                    src={startImage && convertS3ToCloudFront(startImage)}
                                    alt="Start Shift Image"
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        border: `1px solid ${theme.palette.divider}`
                                    }}
                                />
                                <Typography variant="caption" style={{ display: "block", textAlign: "center", marginTop: "4px" }}>
                                    Start Image
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <img
                                    src={endImage && convertS3ToCloudFront(endImage)}
                                    alt="End Shift Image"
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        border: `1px solid ${theme.palette.divider}`
                                    }}
                                />
                                <Typography variant="caption" style={{ display: "block", textAlign: "center", marginTop: "4px" }}>
                                    End Image
                                </Typography>
                            </Grid>
                        </Grid>

                        <div style={{ marginTop: "0.5rem" }}>
                            <Typography variant="subtitle1" style={{ fontWeight: "500", marginTop: "10px" }}>
                                Breaks
                            </Typography>
                            {!breakTime || breakTime.length === 0 ? (
                                <Typography style={{ fontStyle: "italic", color: theme.palette.text.primary }}>
                                    No breaks recorded
                                </Typography>
                            ) : (
                                breakTime.map((brk, index) => (
                                    <Grid container spacing={2} key={index} style={{ marginTop: "8px" }}>
                                        <Grid item xs={10}>
                                            <Typography variant="body1" >
                                                Break {index + 1}: <span style={{ backgroundColor: "#ee5622", color: "white", fontWeight: "600", padding: "2px 5px", borderRadius: "5px" }}>
                                                    {formatTime(brk.start).slice(0, 5) + " to " + formatTime(brk.end).slice(0, 5) + " = " + subtractTime(brk.start, brk.end)}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))
                            )}
                        </div>

                        <div style={{ marginTop: "1rem" }}>
                            <ShiftTimeLine shiftTimelines={props.shift.shiftTimelines} />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
