import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Alert, Button, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useLocation, useHistory, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LockIcon from "@mui/icons-material/Lock";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../components/ErrorAlert";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [showOTP, setSHowOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const params = useParams();
  const post = params.post;

  const verifyOTP = (e) => {
    e.preventDefault();
    if (!email || !otp) {
      setErrorMessage(!email ? "Please provide an email" : "Please provide OTP");
      setShow(true);
      setTimeout(() => {
        setShow(false);
        setErrorMessage("");
      }, 5000);
    } else {
      setIsLoading(true);
      axios.post(`${process.env.REACT_APP_API_BACKEND}/api/otp/confirm`, { email, otp })
        .then(res => {
          if (res.data.success) {
            setShow(true);
            sessionStorage.setItem("password-reset-token", res.data.JWT_TOKEN);
            setMessage("OTP verified successfully");
            setTimeout(() => {
              history.push(`/new-password/${post}`);
              setShow(false);
              setMessage("");
            }, 3000);
          }
        }).catch(err => {
          console.log(err);
          setShow(true);
          setErrorMessage(err.response?.data?.message || "Verification failed");
          setTimeout(() => {
            setShow(false);
            setErrorMessage("");
          }, 5000);
        }).finally(() => {
          setIsLoading(false);
        })
    }
  };
  
  const goBack = () => {
    history.goBack();
  }
  
  const requestOTP = (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage("Please provide an email");
      setShow(true);
      setTimeout(() => {
        setShow(false);
        setErrorMessage("");
      }, 5000);
    } else {
      setIsLoading(true);
      axios.post(`${process.env.REACT_APP_API_BACKEND}/api/${post}/otp`, { email: email })
        .then(res => {
          setSHowOTP(true);
          setMessage(res.data.message);
          setShow(true);
          setTimeout(() => {
            setShow(false);
            setMessage("");
          }, 5000);
        }).catch(err => {
          console.log(err);
          setShow(true);
          setErrorMessage(err.response?.data?.message || "Failed to send OTP");
          setTimeout(() => {
            setShow(false);
            setErrorMessage("");
          }, 5000);
        }).finally(() => {
          setIsLoading(false);
        })
    }
  }

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(/images/login-bg.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: 'hidden',
        overflowX: 'hidden'
      }}
    >
      <Card
        sx={{
          width: {
            xs: 'calc(100% - 32px)', // Full width minus padding on mobile
            sm: '400px'              // Fixed width on larger screens
          },
          m: 2,
          backgroundColor: "#ffffff8d",
          backdropFilter: "blur(10px)",
          boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: {
            xs: 'calc(100vh - 32px)', // Full height minus padding on mobile
            sm: '650px'               // Increased max height
          }
        }}
      >
        <CardContent
          sx={{
            p: { xs: 2.5, sm: 3 }, // Increased padding
            '&:last-child': { pb: { xs: 2.5, sm: 3 } },
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            gap: 2 // Increased gap between direct children
          }}
        >
          {/* Logo and Title - more compact */}
          <Box sx={{ textAlign: 'center', mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
              <Button
                onClick={goBack}
                sx={{
                  position: 'absolute',
                  left: 0,
                  minWidth: 'auto',
                  p: 1,
                  color: theme.palette.text.primary,
                  '&:hover': { bgcolor: 'rgba(0,0,0,0.04)' }
                }}
              >
                <ArrowBackIcon />
              </Button>
              <img
                src="/images/empstar-black.png"
                alt="logo"
                style={{ 
                  height: '55px',
                  marginBottom: '16px',
                }}
              />
            </Box>
            <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
              Reset Password
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
              {showOTP 
                ? "Enter the OTP sent to your email" 
                : "Enter your email to receive a password reset code"}
            </Typography>
          </Box>

          {/* Error/Loading/Success Display */}
          {(isLoading || (show && (errorMessage || message))) && (
            <Box sx={{ my: 1 }}>
              {isLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size={24} />
                </Box>
              )}
              {show && errorMessage && <ErrorAlert error={errorMessage} />}
              {show && message && <Alert severity="success">{message}</Alert>}
            </Box>
          )}

          {/* Form - more compact spacing */}
          <Box 
            component="form" 
            onSubmit={showOTP ? verifyOTP : requestOTP}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2 // Consistent gap between form elements
            }}
          >
            <TextField
              fullWidth
              label="Email Address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              variant="outlined"
              size="medium"
              disabled={showOTP}
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon />
                  </InputAdornment>
                )
              }}
            />

            {showOTP && (
              <TextField
                fullWidth
                label="OTP Code"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
                required
                variant="outlined"
                size="medium"
                sx={{ width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  )
                }}
              />
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{
                py: 1.5,
                px: 3,
                fontSize: '1rem',
                fontWeight: 600,
                borderRadius: 1.5,
                textTransform: 'none',
                width: '100%',
                mt: 1
              }}
            >
              {showOTP ? 'Verify OTP' : 'Send Reset Code'}
              <ArrowForwardIosIcon sx={{ ml: 1.5, fontSize: '0.9rem' }} />
            </Button>
            
            {showOTP && (
              <Button
                onClick={() => {
                  setSHowOTP(false);
                  setOTP("");
                }}
                sx={{
                  color: theme.palette.text.primary,
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  fontWeight: 400
                }}
              >
                Try with a different email?
              </Button>
            )}
          </Box>

          {/* Footer - more compact */}
          <Box sx={{ 
            mt: 'auto', 
            pt: 3, // Increased top padding
            textAlign: 'center'
          }}>
            <Typography 
              sx={{ 
                color: theme.palette.text.primary,
                mb: 1
              }}
            >
              Powered by
            </Typography>
            <img
              src="https://crinitis-ms-app-files.s3.ap-southeast-2.amazonaws.com/assets/crinitis-horizontal-black.png"
              style={{ 
                maxWidth: '125px',
                display: 'block',
                margin: '8px auto 0'
              }}
              alt="Powered by logo"
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
