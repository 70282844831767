import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import InviteEmployee from "../components/InviteEmployee";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Stack from "@mui/material/Stack";
import InviteEmployee from "../../Employees/Employees/InviteEmployee";


//custom style for input text field
const styles = (theme) => ({
  input: {
    height: 40,
    paddingRight: 0,
  },
});
const BatchesHeader = withStyles(styles)((props) => {
  const { classes } = props;
  const [status, setStatus] = useState("All Quantity");
  const [search, setSearch] = useState("");
  return (
    <div>
      <div
        className="header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="left-container">
          <div style={{ fontSize: "1.4rem" }}>Inventory batches</div> <br />
          <span>Search by inventory name, reference id, order number</span>
          <br />
          <TextField
            id="outlined-basic"
            style={{ width: "450px" }}
            value={search}
            // onChange={searchHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0 5px 5px 0",
                      backgroundColor: "#1a237e",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      height: "2.5rem",
                    }}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
              className: classes.input,
            }}
            variant="outlined"
          />
        </div>
        <div className="right-container">
          {/* <Button variant="contained" style={{backgroundColor:"#1a237e",textTransform:"capitalize", fontWeight:"600"}}><AddIcon/> Invite employee</Button> */}
          <Stack direction="row" justifyContent="right">
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#4caf4f",
                fontWeight: "500",
              }}
              startIcon={<ImportExportIcon />}
            >
              Export
            </Button>
          </Stack>
          <Stack spacing={1} direction="row" justifyContent="right">
            <div>
              <div style={{ marginTop: "1.2rem" }}>​Quantity</div>
              <FormControl fullWidth size="small" style={{ width: "10rem" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  //   onChange={handleChange}
                >
                  <MenuItem value={"All Quantity"}>All Quantity</MenuItem>
                  <MenuItem value={"Low Quantity"}>Low Quantity</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <div style={{ marginTop: "1.2rem" }}>Status</div>
              <FormControl fullWidth size="small" style={{ width: "10rem" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  //   onChange={handleChange}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Inactive"}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
});

export default BatchesHeader;
