import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Alert, Button, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useLocation, useHistory, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../components/ErrorAlert";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";

export default function NewPassword() {
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const prt = sessionStorage.getItem("password-reset-token");
  const params = useParams();
  const post = params.post;
  
  useEffect(() => {
    if (!prt) {
      if (post === "user") {
        history.push("/user/login");
      } else {
        history.push("/login");
      }
    }
  }, [prt, post, history]);

  const handlePasswordReset = (e) => {
    e.preventDefault();
    if (!password) {
      setErrorMessage("Please provide a password");
      setShow(true);
      setTimeout(() => {
        setShow(false);
        setErrorMessage("");
      }, 5000);
    } else if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setShow(true);
      setTimeout(() => {
        setShow(false);
        setErrorMessage("");
      }, 5000);
    } else {
      setIsLoading(true);
      axios.post(`${process.env.REACT_APP_API_BACKEND}/api/otp/resetpassword`, {
        password: password,
        confirmPassword: confirmPassword
      }, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${prt}`
        },
      })
        .then((res) => {
          setShow(true);
          setMessage("Password reset successfully");
          setTimeout(() => {
            setShow(false);
            setMessage("");
            sessionStorage.removeItem("password-reset-token");
            if (post === "user") {
              history.push("/user/login");
            } else {
              history.push("/login");
            }
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(err.response?.data?.message || "An error occurred");
          setShow(true);
          setTimeout(() => {
            setShow(false);
            setErrorMessage("");
          }, 5000);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  const goBack = () => {
    history.goBack();
  }

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(/images/login-bg.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: 'hidden',
        overflowX: 'hidden'
      }}
    >
      <Card
        sx={{
          width: {
            xs: 'calc(100% - 32px)', // Full width minus padding on mobile
            sm: '400px'              // Fixed width on larger screens
          },
          m: 2,
          backgroundColor: "#ffffff8d",
          backdropFilter: "blur(10px)",
          boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: {
            xs: 'calc(100vh - 32px)', // Full height minus padding on mobile
            sm: '650px'               // Increased max height
          }
        }}
      >
        <CardContent
          sx={{
            p: { xs: 2.5, sm: 3 }, // Increased padding
            '&:last-child': { pb: { xs: 2.5, sm: 3 } },
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            gap: 2 // Increased gap between direct children
          }}
        >
          {/* Logo and Title - more compact */}
          <Box sx={{ textAlign: 'center', mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
              <Button
                onClick={goBack}
                sx={{
                  position: 'absolute',
                  left: 0,
                  minWidth: 'auto',
                  p: 1,
                  color: theme.palette.text.primary,
                  '&:hover': { bgcolor: 'rgba(0,0,0,0.04)' }
                }}
              >
                <ArrowBackIcon />
              </Button>
              <img
                src="/images/empstar-black.png"
                alt="logo"
                style={{ 
                  height: '55px',
                  marginBottom: '16px',
                }}
              />
            </Box>
            <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
              Create New Password
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
              Please enter your new password below
            </Typography>
          </Box>

          {/* Error/Loading/Success Display */}
          {(isLoading || (show && (errorMessage || message))) && (
            <Box sx={{ my: 1 }}>
              {isLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size={24} />
                </Box>
              )}
              {show && errorMessage && <ErrorAlert error={errorMessage} />}
              {show && message && <Alert severity="success">{message}</Alert>}
            </Box>
          )}

          {/* Form - more compact spacing */}
          <Box 
            component="form" 
            onSubmit={handlePasswordReset}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2 // Consistent gap between form elements
            }}
          >
            <TextField
              fullWidth
              label="New Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              variant="outlined"
              size="medium"
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                )
              }}
            />

            <TextField
              fullWidth
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              variant="outlined"
              size="medium"
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                )
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{
                py: 1.5,
                px: 3,
                fontSize: '1rem',
                fontWeight: 600,
                borderRadius: 1.5,
                textTransform: 'none',
                width: '100%',
                mt: 1
              }}
            >
              Confirm New Password
              <ArrowForwardIosIcon sx={{ ml: 1.5, fontSize: '0.9rem' }} />
            </Button>
          </Box>

          {/* Footer - more compact */}
          <Box sx={{ 
            mt: 'auto', 
            pt: 3, // Increased top padding
            textAlign: 'center'
          }}>
            <Typography 
              sx={{ 
                color: theme.palette.text.primary,
                mb: 1
              }}
            >
              Powered by
            </Typography>
            <img
              src="https://crinitis-ms-app-files.s3.ap-southeast-2.amazonaws.com/assets/crinitis-horizontal-black.png"
              style={{ 
                maxWidth: '125px',
                display: 'block',
                margin: '8px auto 0'
              }}
              alt="Powered by logo"
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
