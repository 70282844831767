import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SalesOrders from "../components/Purchase Orders/Sales/SalesOrders";
import SalesCustomers from "../components/Purchase Orders/Sales/SalesCustomers";
import UnderDevelopmentMessage from "../components/UnderDevelopment/UnderDevelopmentMessage";

function Sales() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <UnderDevelopmentMessage/>
      <div>View insights by employee</div>
      <FormControl size="small" fullWidth>
        <Select
          displayEmpty
          value="hardik.pokiya@crinitis.com.au"
          style={{ maxWidth: "500px" }}
        >
          <MenuItem>hardik.pokiya@crinitis.com.au</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ width: "100%", typography: "body1", marginTop: "1rem" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                style={{ textTransform: "capitalize" }}
                label="Orders"
                value="1"
              />
              <Tab
                style={{ textTransform: "capitalize" }}
                label="Customers"
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <SalesOrders />
          </TabPanel>
          <TabPanel value="2">
            <SalesCustomers />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}

export default Sales;
