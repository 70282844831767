import React from "react";
import CRMLeadsHeader from "../components/CRM/Leads/CRMLeadsHeader";
import CRMLeadsTable from "../components/CRM/Leads/CRMLeadsTable";
import UnderDevelopmentMessage from "../components/UnderDevelopment/UnderDevelopmentMessage";

function CRMLeads() {
  return (
    <div>
      <UnderDevelopmentMessage/>
      <CRMLeadsHeader />
      <CRMLeadsTable />
    </div>
  );
}

export default CRMLeads;
