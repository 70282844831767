import React from "react";
import CRMContactHeader from "../components/CRM/Contacts/CRMContactHeader";
import CRMContactTable from "../components/CRM/Contacts/CRMContactTable";
import UnderDevelopmentMessage from "../components/UnderDevelopment/UnderDevelopmentMessage";

function CRMContact() {
  return (
    <div>
      <UnderDevelopmentMessage />
      <CRMContactHeader />
      <CRMContactTable />
    </div>
  );
}

export default CRMContact;
