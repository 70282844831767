import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useTheme } from "@emotion/react";

const ExportEmployeeDetails = ({ data }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState({
        email: true,
        fname: true,
        lname: true,
        mobNumber: true,
        title: true,
        createdAt: true,
        department: true,
        role: true,
        flag: true
    });
    const [sortBy, setSortBy] = useState("");

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleColumnChange = (event) => {
        setSelectedColumns({
            ...selectedColumns,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSortChange = (event) => {
        setSortBy(event.target.value);
    };

    const handleExport = () => {
        handleClose();
    };

    const filteredData = data.map((item) => {
        const filteredItem = {};
        if (selectedColumns.email) filteredItem.email = item.email;
        if (selectedColumns.fname) filteredItem.fname = item.employeeDetail.fname;
        if (selectedColumns.lname) filteredItem.lname = item.employeeDetail.lname;
        if (selectedColumns.mobNumber) filteredItem.mobNumber = item.employeeDetail.mobNumber;
        if (selectedColumns.title) filteredItem.title = item.employeeDetail.title;
        if (selectedColumns.createdAt) filteredItem.createdAt = item.createdAt;
        if (selectedColumns.department) filteredItem.department = item.department.department;
        if (selectedColumns.role) filteredItem.role = item.role.role;
        if (selectedColumns.flag) filteredItem.flag = item.flag.flag;
        return filteredItem;
    });

    const sortedData = [...filteredData].sort((a, b) => {
        if (!sortBy) return 0;
        const valA = a[sortBy]?.toLowerCase();
        const valB = b[sortBy]?.toLowerCase();
        if (valA < valB) return -1;
        if (valA > valB) return 1;
        return 0;
    });

    return (
        <>
            <Button
                variant="contained"
                startIcon={<ImportExportIcon />}
                onClick={handleOpen}
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                }}
            >
                Export Employee Details
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Select Columns to Export & Sort By</DialogTitle>
                <DialogContent>
                    {Object.keys(selectedColumns).map((key) => (
                        <FormControlLabel
                            key={key}
                            control={
                                <Checkbox
                                    checked={selectedColumns[key]}
                                    onChange={handleColumnChange}
                                    name={key}
                                />
                            }
                            label={key.charAt(0).toUpperCase() + key.slice(1)}
                        />
                    ))}
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Sort By</InputLabel>
                        <Select value={sortBy} onChange={handleSortChange}>
                            <MenuItem value="">None</MenuItem>
                            {Object.keys(selectedColumns).map((key) => (
                                <MenuItem key={key} value={key}>
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <CSVLink data={sortedData} filename="employee_details.csv" onClick={handleExport} style={{ textDecoration: "none" }}>
                        <Button variant="contained" color="primary">
                            Export
                        </Button>
                    </CSVLink>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ExportEmployeeDetails;
