import * as React from 'react';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { useEffect } from 'react';
import { Button, Menu, Popover, TextField, useTheme } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

//week dow 1
moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});


const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})(({ theme, isSelected, isHovered, day }) => ({
    borderRadius: 0,
    //is same month
 

    //of cuurent calendar month
  

    ...(isSelected && {
        backgroundColor: theme.palette.primary.main,
        // color: theme.palette.text.secondary important to override default color
        color: `${theme.palette.text.secondary} !important`,
        borderRadius: '0 !important',
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
            // color: theme.palette.text.primary,
            color: `${theme.palette.text.secondary} !important`,
        },
    }),
    ...(isHovered && {
        backgroundColor: theme.palette.primary[theme.palette.mode],
        // color: theme.palette.text.secondary,  
        color: `${theme.palette.text.secondary} !important`,
        borderRadius: '0 !important',
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary[theme.palette.mode],      
        },
    }),
    ...(day.isoWeekday() === 1 && {
        borderTopLeftRadius: '50% !important',
        borderBottomLeftRadius: '50% !important',
        
    }),
    ...(day.isoWeekday() === 7 && {
        borderTopRightRadius: '50% !important',
        borderBottomRightRadius: '50% !important',
    }),
    
}));

const isInSameWeek = (dayA, dayB) => {
    if (dayB == null) {
        return false;
    }

    return dayA.isSame(dayB, 'isoWeek');
};

function Day(props) {
    const { day, selectedDay, hoveredDay, ...other } = props;

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
            //color of M T W T F S S IN header
           slotProps={{ day: { sx: { color: 'white' } } }}


        />
    );
}






export default function WeekPicker(props) {
    const { handleNextWeek, handlePreviousWeek, handleWeekChange, week, isMobile } = props;
    const [hoveredDay, setHoveredDay] = React.useState(null);
    //get 1st day of week from week like 2021-W51
    const [value, setValue] = React.useState(moment(week, 'YYYY-[W]WW').startOf('isoWeek'));
    const theme = useTheme();
    useEffect(() => {
        setValue(moment(week, 'YYYY-[W]WW').startOf('isoWeek'));
    }
        , [week]);


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleValueChange = (newValue) => {
        setValue(newValue);
        //get iso week from moment
        let week = newValue.isoWeek();

        //get year from moment
        let year = newValue.year();
        if (week < 10) {
            week = '0' + week;
        }
        let weekString = year + '-W' + week;
        handleWeekChange(weekString);
        setTimeout(() => {
            handleClose();
        }
            , 750);
            
    }





    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',

        }}>

            <Button
                variant="contained"
                style={{ textTransform: "none", fontWeight: "500" }}
                startIcon={<ArrowBackIcon sx={{ color: theme.palette.text.secondary }} />}
                onClick={handlePreviousWeek}
            >
                {!isMobile && "Previous"}
            </Button>

            <Button
                aria-describedby={id}
                variant="outlined"
                onClick={handleClick}
                sx={{  margin: '0 10px',  padding: '7px 10px', width: '200px', color: theme.palette.text.primary }}
            >
                
                {value.startOf('isoWeek').format('MMM Do')} - {value.endOf('isoWeek').format('MMM Do')}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateCalendar
                        value={value}
                        onChange={handleValueChange}
                        showDaysOutsideCurrentMonth
                        displayWeekNumber
                        slots={{ day: Day }}
                        slotProps={{
                            day: (ownerState) => ({
                                selectedDay: value,
                                hoveredDay,
                                onPointerEnter: () => setHoveredDay(ownerState.day),
                                onPointerLeave: () => setHoveredDay(null),
                            }),
                        }}
                        //change background color of paper
                         // MuiPickersDay: {
      //   styleOverrides: {
      //     root: {
      //       color: '#7c7c7c',
      //       borderRadius: 0,
      //       borderWidth: 0,
      //       borderColor: '#2196f3',
      //       border: '0px solid',
      //     },
      //     dayOutsideMonth : {
            
      //       color: '#ffffff',
      //       // // boxShadow: 'inset  0 3px 30.5px 32px rgba(0, 0, 0, 0.312)',
      //       // backgroundColor: '#000000',
      //       // borderRadius: '50% !important',
      //     },
          

      //   },
      // },
                        sx={{ bgcolor: theme.palette.card.bg , 
                            color: theme.palette.text.primary,
                            '& .MuiPickersCalendarHeader-switchHeader': {
                                color: theme.palette.text.primary,
                            },
                            '& .MuiDayCalendar-weekNumber': {
                                color: `${theme.palette.text.primary} !important`,
                            },
                            '& .MuiPickersDay-dayOutsideMonth': {
                                color: '#7c7c7c',
                            },
                           
                        }}
                      
                    />
                </LocalizationProvider>
            </Popover>
            <Button
                variant="contained"
                style={{ textTransform: "none", fontWeight: "500" }}
                endIcon={<ArrowForwardIcon sx={{ color: theme.palette.text.secondary }} />}
                onClick={handleNextWeek}
            >
                {!isMobile && "Next"}
            </Button>
        </div>
    );
}
