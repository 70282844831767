import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { withStyles, styled } from "@material-ui/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import SuccessAlerts from "../SuccessAlert";
import ErrorAlerts from "../ErrorAlert";
import { CircularProgress } from "@mui/material";
import { parseISO } from "date-fns";
// custom styling for input text field
const styles = (theme) => ({
  input: {
    height: 40,
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
});

const UserOnboardingForm = withStyles(styles)((props) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { classes } = props;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [loading, setLoading] = useState(false);
  // state for handling step count
  const [activeStep, setActiveStep] = useState(0);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mobNumber, setMobNumber] = useState("");
  const [DOB, setDOB] = useState("");
  const [gender, setGender] = useState("Male");
  const [companyName, setCompanyName] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const history = useHistory();
  const location = useLocation();

  // function to go to next step
  const handleNext1 = () => {
    //check if all fields are filled out
    if (
 
      fname !== "" &&
      lname !== "" &&
      mobNumber !== "" &&
      DOB !== ""
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      alert("Please fill out all fields");
    }
  };
  function removeNonAlphabet(str) {
        return str.replace(/[^a-zA-Z]/g, '');
    }
  const handleNext2 = () => {
    if (companyName !== "" && companyDescription !== "") {
      let testname = removeNonAlphabet(companyName);
      if(testname.length < 3){
        alert("Company name must be at least 3 characters long other than numbers and special characters");
      }else{

        submitHandler();
      }
    } else {
      alert("Please fill out all fields");
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // function to go to previous step
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //function to reste all the step count
  const handleReset = () => {
    setActiveStep(0);
  };


  useEffect(() => {
    if (userInfo.flag == "Registered") {
      history.push("/onboard");
    }
    if (userInfo.flag == "Onboarding") {
      history.push("/registered");
    }
  }, [userInfo]);
  const submitHandler = () => {
    const data = {  
      firstName: fname,
      lastName: lname,
      mobNumber: mobNumber,
      DOB: DOB,
          
      name: companyName,
      description: companyDescription,


    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
      },
    };
    window.scrollTo(0, 0)
    setLoading(true);
console.log("submitted");
    axios
      .post(
        `${process.env.REACT_APP_API_BACKEND}/api/company/register`,
        data,
        config
      )
      .then((res) => {
        console.log(res.data.message);
        setLoading(false);
        setSuccess(res.data.message);
        userInfo.flag = "Company Registered";
        userInfo.companyId = res.data.companyId;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
       
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        setLoading(false);
        console.log(err.response.data.message);
        setError(err.response.data.message);
        // console.log(errormessage);
      });
  };
  if (success) {
    setTimeout(() => {
      setSuccess("");
      history.push("/");
    }, 2000);
  }
  if (error) {
    setTimeout(() => {
      setError("");
    }, 3000);
  }
  return (
    <div>
      <span style={{ fontSize: "1.3rem" }}>
        Please Complete your details below
      </span>
      <div style={{ textAlign: "center", margin: "auto", marginTop: "1rem",zIndex:"1000",position:"absolute", right:"45vw",top:"10vh" }}>
          {loading && <CircularProgress color="secondary" />}
        </div>
      <div style={{ textAlign: "center", margin: "auto", marginTop: "1rem",zIndex:"1000",position:"absolute", right:"36vw",top:"10vh" }}>
          {error && <ErrorAlerts error={error} />}
          {success && <SuccessAlerts success={success} />}
        </div>
      <Box
        sx={{ maxWidth: "100%" }}
        style={{
          margin: "2.5rem 1.5rem",
          backgroundColor: "white",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
      >
        <form onSubmit={submitHandler}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ padding: "1rem 2rem" }}
          >
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}> Personal Information</span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  
                  <Grid item xs={12} md={6}>
                    <div>First name*</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="First name"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required
                      variant="outlined"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Last name*</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Last name"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required
                      variant="outlined"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Mobile Number*</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Mobile Number"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      type="number"
                      required={true}
                      variant="outlined"
                      value={mobNumber}
                      onChange={(e) => setMobNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Date of birth*</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={parseISO(DOB)}
                        format="dd/MM/yyyy"
                        onChange={(newValue) => {
                          setDOB(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            placeholder="DD/MM/YYYY"
                            fullWidth
                            variant="standard"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Gender</div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      fullWidth
                      input={<OutlinedInput />}
                      size="small"
                      onChange={(e) => setGender(e.target.value)}
                      value={gender}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                      <MenuItem value={"Not Stated"}>Not Stated</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                      
                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext1}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <span style={{ fontSize: "1.2rem" }}>
                  {" "}
                  Company Information
                </span>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2} style={{ margin: "1.2rem 0" }}>
                  
                  
                  <Grid item xs={12} md={6}>
                    <div>Company Name</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Company Name"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>Company Description</div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Company Description"
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                      required={false}
                      variant="outlined"
                      value={companyDescription}
                      onChange={(e) => setCompanyDescription(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ mb: 2 }}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext2}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Submit
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </form>
        {activeStep === 3 && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    </div>
  );
});

export default UserOnboardingForm;

// <Box sx={{ maxWidth: "100%" }}>
// <Stepper activeStep={activeStep} orientation="vertical">
//   {steps.map((step, index) => (
//     <Step key={step.label}>
//       <StepLabel
//         optional={
//           index === 3 ? (
//             <Typography variant="caption">Last step</Typography>
//           ) : null
//         }
//       >
//         {step.label}
//       </StepLabel>
//       <StepContent>
//         <Typography>{step.description}</Typography>
//         <Box sx={{ mb: 2 }}>
//           <div>
//             <Button
//               disabled={index === 0}
//               onClick={handleBack}
//               sx={{ mt: 1, mr: 1 }}
//             >
//               Back
//             </Button>
//             <Button
//               variant="contained"
//               onClick={handleNext}
//               sx={{ mt: 1, mr: 1 }}
//             >
//               {index === steps.length - 1 ? "Finish" : "Next"}
//             </Button>
//           </div>
//         </Box>
//       </StepContent>
//     </Step>
//   ))}
// </Stepper>
// {activeStep === steps.length && (
//   <Paper square elevation={0} sx={{ p: 3 }}>
//     <Typography>All steps completed - you&apos;re finished</Typography>
//     <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//       Reset
//     </Button>
//   </Paper>
// )}
// </Box>
