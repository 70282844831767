import React, { useEffect, useRef } from "react";
import moment from 'moment';
import { TableCell, TableHead, TableRow, Table, TableBody, Box, TableContainer, useTheme } from "@mui/material";
import ShiftBox from "./ShiftBox";

moment.updateLocale('en', {
    week: {
        dow: 1,
    }
});

const ShiftCalender = (props) => {
    const { data, month, year, user } = props;
    const [weeks, setWeeks] = React.useState([]);
    const containerRef = useRef(null);
    const theme = useTheme();
    const shiftedWeekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    useEffect(() => {
        const firstDay = moment(`${year}-${moment().month(month).format('MM')}-01`);
        const lastDay = moment(firstDay).endOf('month');
        const weeks = [];
        let currentDay = firstDay.clone().startOf('week').add(1, 'day');

        while (currentDay.isBefore(lastDay)) {
            const week = [];
            for (let i = 0; i < 7; i++) {
                week.push(currentDay.toISOString().split('T')[0]);
                currentDay.add(1, 'day');
            }
            weeks.push(week);
        }
        setWeeks(weeks);
    }, [month, year]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Load more data here if needed
                }
            });
        }, { threshold: 0.5 });

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [containerRef]);

    return (
        <div>
            <TableContainer sx={{ border: `1px solid ${theme.palette.miscBg.bg}`, borderRadius: '20px', backgroundColor: theme.palette.background.paper, height: '100%', width: '100%', overflow: 'auto' }}
                ref={containerRef}
            >
                <Table sx={{ border: `1px solid ${theme.palette.miscBg.bg}`, borderRadius: '5px',
                    '& .MuiTableCell-sizeMedium': {
                        padding: '10px 10px',
                    },
                }}>
                    <TableHead>
                        <TableRow>
                            {shiftedWeekDays.map((day, index) => (
                                <TableCell
                                    align="center"
                                    key={index} style={{ borderRadius: '5px' }}
                                >
                                    {day}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {weeks.map((week, weekIndex) => (
                            <TableRow key={weekIndex}>
                                {week.map((day, dayIndex) => (
                                    <TableCell
                                        key={dayIndex}
                                        align="center"
                                        style={{
                                            width: '14.2857%',
                                            border: `1px solid ${theme.palette.miscBg.bg}`,
                                            opacity: moment(day).format('MMMM') === month ? 1 : 0.5,
                                        }}
                                    >
                                        {moment(day).format('DD')}
                                        {data.some(item => moment(item.startDate).isSame(day, 'day')) && (
                                            <div>
                                                {data.filter(item => moment(item.startDate).isSame(day, 'day')).map((item) => (
                                                    <Box key={item.id}> {/* Use a unique identifier here */}
                                                        <ShiftBox
                                                            shift={item}
                                                            employeeId={user.id}
                                                            employee={user.employeeDetail}
                                                        />
                                                    </Box>
                                                ))}
                                            </div>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ShiftCalender;
