import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CircularProgress, Grid, Snackbar } from '@mui/material';
import MapComponent from './MapComponent';
import LocationSidebar from './LocationSidebar';
import { useSelector } from "react-redux";
import axios from 'axios';

const GeoTagging = () => {
  const [location, setLocation] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [radius, setRadius] = useState(30);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null); 
  const [loading, setLoading] = useState(false);

  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation);
    setMarkerPosition(selectedLocation);
  };

  const handleMarkerDrag = (newPosition) => {
    setMarkerPosition(newPosition);
  };
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  function truncateBranchName(comapnyName, branchName) {
    //if character length is greater than 20
    let cmpName;
    let brnchName;
    if (comapnyName.length > 10) {
      cmpName = comapnyName.substring(0, 5) + "...";
    }
    else {
      cmpName = comapnyName;
    }
    if (branchName.length > 10) {
      brnchName = branchName.substring(0, 5) + "...";
    }
    else {
      brnchName = branchName;
    }
    return `${cmpName} - ${brnchName}`;
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };

  const handleMarkLocation = async () => {
    if (markerPosition && radius) {
      try {
        const payload = {
          lat: markerPosition.lat,
          lon: markerPosition.lon,
          radius: radius
        };
        axios.post(`${process.env.REACT_APP_API_BACKEND}/api/branch/addLatLng`, payload, config)
        .then((response) => {
            setSuccessMessage(response.data.message);
        })
        .catch((error) => {
            setErrorMessage('Error marking location');
        });
        console.log(payload)
        // Optionally, you can show a success message or perform other actions after successful API call
      } catch (error) {
        console.error('Error marking location:', error);
        // Handle error (e.g., show error message)
      }
    } else {
      console.error('Marker position or radius is missing.');
      // Handle missing data (e.g., show error message)
    }
  };

  const fetchBranchLocation = async () => {
    setLoading(true);
    try {
    axios.get(`${process.env.REACT_APP_API_BACKEND}/api/branch/getLatLng`, config)
    .then((response) => {
        // setLocation({ lat: response.data.lat, lon: response.data.lon });
        // setRadius(response.data.radius);
        // setMarkerPosition({ lat: response.data.lat, lon: response.data.lon });
        if(response.data.lat && response.data.lon && response.data.radius){
            setLocation({ lat: response.data.lat, lon: response.data.lon });
            setRadius(response.data.radius);
            setMarkerPosition({ lat: response.data.lat, lon: response.data.lon});
        }
        else if(response.data.lat && response.data.lon && !response.data.radius){
            setLocation({ lat: response.data.lat, lon: response.data.lon });
            setMarkerPosition({ lat: response.data.lat, lon: response.data.lon });
        }

        setLoading(false);
    }
    )
    .catch((error) => {
        console.log(error)
        setErrorMessage('Error fetching branch location');
        setLoading(false);
    });
    } catch (error) {
        console.error('Error fetching branch location:', error);
        }
    }
    useEffect(() => {
        fetchBranchLocation();
    }
    , []);



  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: window.innerWidth <= 768 ? 'column' : 'row', 
      padding: window.innerWidth <= 768 ? "10px 10px" : "10px 20px"
    }}>


    <Snackbar sx={{zIndex: "100000"}} open={successMessage} autoHideDuration={3000} onClose={() => setSuccessMessage(null)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%' , backgroundColor: '#065300' , color: 'white' }}>
            {successMessage}
        </Alert>
    </Snackbar>
    <Snackbar sx={{zIndex: "100000"}} open={errorMessage} autoHideDuration={3000} onClose={() => setErrorMessage(null)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setErrorMessage(null)} severity="error" sx={{ width: '100%' , backgroundColor: '#690000' , color: 'white' }}>
            {errorMessage}
        </Alert>
    </Snackbar>



      <LocationSidebar onSelectLocation={handleLocationSelect} onSetRadius={setRadius} setErrorMessage={setErrorMessage} />
      
      <div style={{ 
        flex: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'start', 
        alignItems: 'center', 
        width: '100%',
        marginLeft: window.innerWidth <= 768 ? '0' : '20px'
      }}>
        <div style={{ 
          width: '100%', 
          display: 'flex', 
          justifyContent: window.innerWidth <= 768 ? 'center' : 'right', 
          alignItems: window.innerWidth <= 768 ? 'center' : 'right'
        }}>
          <Button
            variant="contained"
            color="primary"
            style={{ 
              marginTop: '20px',
              width: window.innerWidth <= 768 ? '100%' : 'auto'
            }}
            onClick={handleMarkLocation}
            disabled={!markerPosition || !radius}
          >
            Mark Location
          </Button>
        </div>
        {loading ? (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <CircularProgress style={{ marginTop: '20px' }} />
            </div>
        ) : (
        location ? (
          <Grid container justifyContent="center" style={{ marginTop: 20, width: '100%' }}>
            <Grid item xs={12} sm={12} md={10} lg={8}>
              <MapComponent
                location={location}
                draggable={true}
                onMarkerDrag={handleMarkerDrag}
                markerPosition={markerPosition}
                radius={radius}
                markerName={truncateBranchName(userInfo.companyName, userInfo.branchName)}
              />
            </Grid>
          </Grid>
        ) : 
        <div style={{ marginTop: '20px', width: '100%' }}>
            <Card style={{ width: '100%', padding: '20px', borderRadius: '20px' }}>
                <h3 style={{ textAlign: 'center' }}>No location marked yet</h3>
                <p style={{ textAlign: 'center' }}>Use Search Location to search a city or landmark and mark the location</p>
            </Card>
            
        </div>

        )}


       
      </div>
    </div>
  );
};

export default GeoTagging;
