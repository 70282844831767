import React, { useState } from 'react';
import { AppBar, Tab, Tabs, Typography, Box, Container } from '@mui/material';
import GeneralSettings from '../components/My Company/Settings';
import Genral from '../components/My Company/Settings';

const customStyles = {
  tab: {
    fontSize: '1.5rem',
    fontWeight: '400',
    // Styles for inactive tabs
    '&.MuiTab-root': { 
        color: 'black',
    },
    '&.Mui-selected': {
        // Styles for the active tab
        backgroundColor: '#2196F3', // Change this to your desired active tab color
        color: 'white', // Change this to your desired active tab text color
        borderRadius: '20px 20px 0px 0px',
      },
  },
  tabPanel: {
    backgroundColor: '#EFEFEF', // Change this to your desired inactive tab content color
  },
};

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div
      hidden={value !== index}
      sx={customStyles.tabPanel} // Apply custom styles to inactive tab content
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const SettingsPage = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div style={{marginTop: '20px'}}>
      {/* <TabPanel value={tabValue} index={0}>
        <GeneralSettings/>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Typography variant="h6">Notification Settings</Typography>
        <Genral/>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Typography variant="h6">Profile Settings</Typography>
      </TabPanel> */}

      <GeneralSettings/>
      </div>
  );
};

export default SettingsPage;
