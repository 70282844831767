import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Dialog, Paper, Snackbar, useTheme } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const KioskRequests = () => {
    const [pendingRequests, setPendingRequests] = useState([]);
    const [approvedRequests, setApprovedRequests] = useState([]);
    const [rejectedRequests, setRejectedRequests] = useState([]);
    const [activCountMsg, setActivCountMsg] = useState(null);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [conformationAction, setConformationAction] = useState(null);
    const [requestId, setRequestId] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(true);
    const theme = useTheme();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;
    const config = {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
    };

    const fetchRequests = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_BACKEND}/api/branch/kioskRequests`, config)
            .then(res => {
                setPendingRequests(res.data.pendingRequests);
                setApprovedRequests(res.data.approvedRequests);
                setRejectedRequests(res.data.rejectedRequests);
                setActivCountMsg(res.data.activCountMsg);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchRequests();
    }, []);

    const handleAction = ( ) => {
        axios.patch(`${process.env.REACT_APP_API_BACKEND}/api/branch/kioskRequestAction`,
            { requestId, action: conformationAction }, config)
            .then(res => {
                setSuccess(res.data.message);
                fetchRequests();
            })
            .catch(err => {
                console.log(err);
                setError(err.response.data.error);
            });


    }


    return (
        <div>
            <Snackbar 
                open={error === '' ? false : true}
                autoHideDuration={3000}
                onClose={() => setError('')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setError('')} severity="error">
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar
                open={success === '' ? false : true}
                autoHideDuration={3000}
                onClose={() => setSuccess('')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setSuccess('')} severity="success">
                    {success}
                </Alert>
            </Snackbar>


            {/* <Paper sx={{ borderRadius: "10px", padding: "20px", backgroundColor: theme.palette.card.bg, marginBottom: "10px" }}>
                {activCountMsg && activCountMsg}
            </Paper> */}
            <Box sx={{marginBottom: "10px"}}>
            <Alert severity="info">
            {activCountMsg && activCountMsg}
            </Alert>
            </Box>
            <Paper sx={{ borderRadius: "10px", padding: "20px", backgroundColor: theme.palette.miscBg.bg}}>
                <h2>Pending Requests</h2>
                {loading ?
                    <div>Loading...</div> :
                    <div>
                        {pendingRequests.length === 0 ? <p>No pending requests</p> :
                            pendingRequests.map((request, index) => (
                                <Paper key={index} sx={{ borderRadius: "10px", padding: "10px", marginBottom: "10px", }}>
                                    <p>
                                        <span style={{ textDecoration: "underline"}}>Request By:</span> 
                                        <span style={{ fontWeight: "bold", marginLeft: "5px" }}>{request.requesterName}</span>
                                
                                        </p>
                                    <p>Requested on: {new Date(request.createdAt).toTimeString()}</p>
                                    <p>#RequestId: {request.id}</p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <button
                                        style={{
                                            backgroundColor: theme.palette.success.main,
                                            color: theme.palette.text.secondary,
                                            padding: '0.5px',
                                            border: 'none',
                                            borderRadius: '20px',
                                            width: '100%',
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            margin: '0.5rem'
                                        }}
                                        onClick={() => {
                                            setConformationAction('approve');
                                            setConfirmationDialogOpen(true);
                                            setRequestId(request.id);
                                        }}
                                    >
                                       Approve
                                    </button>
                                    <button
                                        style={{
                                            backgroundColor: theme.palette.button.red,
                                            color: theme.palette.text.secondary,
                                            padding: '0.5rem',
                                            border: 'none',
                                            borderRadius: '20px',
                                            width: '100%',
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            margin: '0.5rem'
                                        }}
                                        onClick={() => {
                                            setConformationAction('reject');
                                            setConfirmationDialogOpen(true);
                                            setRequestId(request.id);
                                        }}
                                    >
                                        Reject
                                    </button>
                                    </div>
                                </Paper>
                            ))
                        }
                    </div>
                }

            </Paper>
            <Paper sx={{ borderRadius: "10px", padding: "20px", backgroundColor: theme.palette.card.bg, marginTop: "10px" }}>
                <h2>Previous Requests</h2>
                {loading ?
                    <div>Loading...</div> :
                    <div>
                        {rejectedRequests.length === 0 ? <p>No rejected requests</p> :
                            (
                                <Accordion>
                                    <AccordionSummary>
                                        Rejected Requests
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            rejectedRequests.map((request, index) => (
                                                <Paper key={index} sx={{ borderRadius: "10px", padding: "10px", marginBottom: "10px", backgroundColor: theme.palette.miscBg.bg }}>
                                                    <p>
                                                        <span style={{ textDecoration: "underline"}}>Request By:</span> 
                                                        <span style={{ fontWeight: "bold", marginLeft: "5px" }}>{request.requesterName}</span>
                                                
                                                        </p>
                                                    <p>Requested on: {new Date(request.createdAt).toTimeString()}</p>
                                                    {request.actionBy && <p>Rejected by: {request.actionBy}</p>}
                                                    <p>#RequestId: {request.id}</p>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <button
                                                        style={{
                                                            backgroundColor: theme.palette.success.main,
                                                            color: theme.palette.text.secondary,
                                                            padding: '0.5rem',
                                                            border: 'none',
                                                            borderRadius: '20px',
                                                            width: '100%',
                                                            fontSize: '1rem',
                                                            fontWeight: 'bold',
                                                            margin: '0.5rem'
                                                        }}
                                                        onClick={() => {
                                                            setConformationAction('approve');
                                                            setConfirmationDialogOpen(true);
                                                            setRequestId(request.id);
                                                        }}
                                                    >
                                                       Approve
                                                    </button>
                                                    </div>
                                                </Paper>
                                            ))
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }

                        {approvedRequests.length === 0 ? <p>No approved requests</p> :
                            (
                                <Accordion style={{ marginTop: "10px"}}>
                                    <AccordionSummary>
                                        Approved Requests
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            approvedRequests.map((request, index) => (
                                                <Paper key={index} sx={{ borderRadius: "10px", padding: "10px", marginBottom: "10px", backgroundColor: theme.palette.miscBg.bg }}>
                                                    <p>
                                                        <span style={{ textDecoration: "underline"}}>Request By:</span> 
                                                        <span style={{ fontWeight: "bold", marginLeft: "5px" }}>{request.requesterName}</span>
                                                    </p>
                                                    <p>Requested on: {new Date(request.createdAt).toTimeString()}</p>
                                                    {request.actionBy && <p>Approved by: {request.actionBy}</p>}
                                                    <p>#RequestId: {request.id}</p>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    
                                                    <button
                                                        style={{
                                                            backgroundColor: theme.palette.button.red,
                                                            color: theme.palette.text.secondary,
                                                            padding: '0.5rem',
                                                            border: 'none',
                                                            borderRadius: '20px',
                                                            width: '100%',
                                                            fontSize: '1rem',
                                                            fontWeight: 'bold',
                                                            margin: '0.5rem'
                                                        }}
                                                        onClick={() => {
                                                            setConformationAction('reject');
                                                            setConfirmationDialogOpen(true);
                                                            setRequestId(request.id);
                                                        }}
                                                    >
                                                        Reject
                                                    </button>
                                                    </div>
                                                </Paper>
                                            ))
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }
                    </div>
                }
            </Paper>

            <Dialog open={confirmationDialogOpen} 
                onClose={() => {
                    setConfirmationDialogOpen(false);
                    setConformationAction(null);
                    setRequestId(null);
                }}
            >
                <div style={{ padding: "20px", backgroundColor: theme.palette.card.bg, borderRadius: "10px" }}>
                    <h2>Are you sure you want to {conformationAction}?</h2>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button
                            style={{
                                backgroundColor: theme.palette.success.main,
                                color: "#000",
                                padding: '0.5px',
                                border: 'none',
                                borderRadius: '20px',
                                width: '100%',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                margin: '0.5rem'
                            }}
                            onClick={() => {
                                handleAction();
                                setConfirmationDialogOpen(false);
                                setConformationAction(null);
                                setRequestId(null);

                            }}
                        >
                            Yes
                        </button>
                        <button
                            style={{
                                backgroundColor: theme.palette.button.red,
                                color: theme.palette.text.primary,
                                padding: '0.5rem',
                                border: 'none',
                                borderRadius: '20px',
                                width: '100%',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                margin: '0.5rem'
                            }}
                            onClick={() => {
                                setConfirmationDialogOpen(false);
                                setConformationAction(null);
                                setRequestId(null);
                            }}

                        >
                            No
                        </button>
                    </div>
                </div>
            </Dialog>



        </div>
    )
}

export default KioskRequests;