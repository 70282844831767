import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

// const tableStyles = makeStyles({
//     tableRow: {
//       height: 10,
//     },
//     tableCell: {
//       padding: "8px 0",
//     },
//     bodyCell: {
//       padding: "5px 16px",
//     },
//   });
//   const StyledTableRow = withStyles((theme) => ({
//     root: {
//       height: 20,
//     },
//   }))(TableRow);
//   const StyledTableCell = withStyles((theme) => ({
//     root: {
//       padding: "10px 5px",
//     },
//   }))(TableCell);

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: 10,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "8px 0",
}));

const tableStyles = styled({
  tableRow: {
    height: 10,
  },
  tableCell: {
    padding: "8px 0",
  },
  bodyCell: {
    padding: "5px 16px",
  },
});





function Role() {
    const classes = tableStyles();
  return <div>
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <Item>
              {" "}
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <StyledTableRow >
                      <StyledTableCell  style={{ fontWeight: "600", fontSize: "1.1rem" }}>
                      Role Info
                      </StyledTableCell>
                      <StyledTableCell
                        
                        align="right"
                      ></StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow 
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <StyledTableCell
                        
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        Position Title
                      </StyledTableCell>
                      <StyledTableCell
                        
                      >
                        Position Title
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow 
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <StyledTableCell
                        
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        Position Type
                      </StyledTableCell>
                      <StyledTableCell
                        
                      >
                        Position Type
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow 
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <StyledTableCell
                        
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        Department
                      </StyledTableCell>
                      <StyledTableCell
                        
                      >
                        Department
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow 
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <StyledTableCell
                        
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        Start date
                      </StyledTableCell>
                      <StyledTableCell
                        
                      >
                        Start date
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow 
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <StyledTableCell
                        
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        Probation period
                      </StyledTableCell>
                      <StyledTableCell
                        
                      >
                        Probation period
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow 
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <StyledTableCell
                        
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        Termination date
                      </StyledTableCell>
                      <StyledTableCell
                        
                      >
                       Termination date
                      </StyledTableCell>
                    </StyledTableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Item>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Item>
              {" "}
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <StyledTableRow >
                      <StyledTableCell  style={{ fontWeight: "600", fontSize: "1.1rem" }}>
                      Payroll/Salary info
                      </StyledTableCell>
                      <StyledTableCell
                        
                        align="right"
                      ></StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow 
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <StyledTableCell
                        
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        Position Salary
                      </StyledTableCell>
                      <StyledTableCell
                        
                      >
                        Position Salary
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow 
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <StyledTableCell
                        
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        Pay cycle
                      </StyledTableCell>
                      <StyledTableCell
                        
                      >
                        Pay cycle
                      </StyledTableCell>
                    </StyledTableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Item>
          </Grid>
          </Grid>
      </Box>
  </div>;
}

export default Role;
