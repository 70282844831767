import { createTheme } from "@mui/material";

const newTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: "#EAAA00", // Mustard yellow
      light: "#FFD166",
      dark: "#E58E1A",
      contrastText: "#2D3047",
    },
    secondary: {
      main: "#FFB81C", // Golden yellow
      light: "#FFD166",
      dark: "#F7A41D",
      contrastText: "#2D3047",
    },
    background: {
      default: "#f2f2f2",
      paper: "#FFFFFF",
      alert: "#E54B4B",
    },
    text: {
      primary: "#000000",
      secondary: "#ffffff",
      contrastText: "#8c8c8c",
      disabled: "#9DA3BB",
      white: "#FFFFFF",
    },
    card: {
      bg: "#FFFFFF",
      text: "#2D3047",
      alt: "#F8F9FB",
      shadow: "rgba(0, 0, 0, 0.08)",
    },
    miscBg: {
      bg: "rgba(228, 228, 228, 1)",
      bg2: "rgba(45, 48, 71, 0.16)",
    },
    dialog: {
      primary: "#2D3047",
      secondary: "#FFB81C",
      paper: "#FFFFFF",
      bg: "#F8F9FB",
    },
    button: {
      blue: "#F7A41D", // Changed to mustard yellow
      gold: "#FFB81C",
      red: "#bf3131",
      green: "#06A77D",
      grey: "#9DA3BB",
    },
    success: {
      main: "#347928",
      light: "#4DD8AF",
      dark: "#05865F",
    },
    error: {
      main: "#bf3131",
      light: "#FF6B6B",
      dark: "#C43535",
    },
    warning: {
      main: "#F7A41D",
      light: "#FFD166",
      dark: "#E58E1A", 
    },
    info: {
      main: "#3D9DF3",
      light: "#64B5F6",
      dark: "#1976D2",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          padding: '8px 24px',
          borderRadius: '5px',
          boxShadow: 'none',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            transform: 'translateY(-1px)',
          },
        },
        contained: {
          '&.MuiButton-containedPrimary': {
            color: '#FFFFFF',

          },
          // '&.MuiButton-containedSecondary': {
          //   '&:hover': {
          //     backgroundColor: '#F7A41D',
          //   },
          // }

        },
        outlined: {
          borderWidth: '1px',
          borderColor: '#c4c4c4',
          '&:hover': {
            borderWidth: '1px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        },
        elevation1: {
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          backgroundColor: '#F8F9FB',
          padding: '4px',
        },
        indicator: {
          height: '3px',
          borderRadius: '20px',
          backgroundColor: '#F7A41D', // Changed from blue to mustard yellow

        }
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '0.95rem',
          minHeight: '48px',
          borderRadius: '8px',
          '&.Mui-selected': {
            backgroundColor: 'rgba(247, 164, 29, 0.08)', // Changed from blue to mustard yellow
          },
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: 100000,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF',
          color: '#2D3047',
          backgroundImage: 'none',
          boxShadow: '0 10px 40px rgba(0, 0, 0, 0.12)',
          padding: '12px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          fontWeight: 600,
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#F7A41D', // Changed from blue to mustard yellow
              borderWidth: '2px',
            },
          }
        }
      }
    },
    MuiDayCalendar: {
      styleOverrides: {
        root: {
          color: '#2d3e50',
          borderRadius: 0,
          borderWidth: 0,
          borderColor: '#F7A41D', // Changed from blue to mustard yellow
          border: '0px solid',
        },
        weekNumber: {
          color: '#2D3047 !important',
        },
        weekNumberLabel: {
          color: '#2D3047 !important',
        },
        weekDayLabel: {
          color: '#2D3047 !important',
          fontWeight: 600,
        },
        switchHeader: {
          color: '#2D3047 !important',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: '50%',
          '&.Mui-selected': {
            backgroundColor: '#F7A41D !important', // Changed from blue to mustard yellow
            color: '#2D3047',
            fontWeight: 600,
          }
        }
      }
    },

    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          color: '#2D3047 !important',
          backgroundColor: 'rgba(247, 164, 29, 0.08)', // Changed from blue to mustard yellow
          borderRadius: '10px',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchViewIcon: {
          color: '#2D3047 !important',
        },
        label: {
          fontSize: '1rem',
          fontWeight: 500,
        }
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          color: '#2D3047 !important',
          '& .MuiSvgIcon-root': {
            color: '#2D3047 !important',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          fontWeight: 500,
        },
        colorPrimary: {
          backgroundColor: 'rgba(247, 164, 29, 0.12)', // Changed from blue to mustard yellow
          color: '#F7A41D', // Changed from blue to mustard yellow
        },
        colorSecondary: {
          backgroundColor: 'rgba(255, 184, 28, 0.15)',
          color: '#F7A41D',
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          margin: '2px 6px',
          paddingTop: '8px',
          paddingBottom: '8px',
          '&.Mui-selected': {
            backgroundColor: 'rgba(247, 164, 29, 0.08)', // Changed from blue to mustard yellow
          },
          '&:hover': {
            backgroundColor: 'rgba(247, 164, 29, 0.04)', // Changed from blue to mustard yellow
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
        },
        colorPrimary: {
          backgroundColor: '#F7A41D', // Changed from blue to mustard yellow
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '&.Mui-selected': {
            backgroundColor: 'rgba(247, 164, 29, 0.08)', // Changed from blue to mustard yellow
          },
          '&:hover': {
            backgroundColor: 'rgba(247, 164, 29, 0.04)', // Changed from blue to mustard yellow
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(45, 48, 71, 0.12)',
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 8,
        },
        track: {
          borderRadius: 22 / 2,
          backgroundColor: '#E9E9EA',
        },
        thumb: {
          boxShadow: 'none',
        },
        switchBase: {
          '&.Mui-checked': {
            '& + .MuiSwitch-track': {
              backgroundColor: '#F7A41D', // Changed from blue to mustard yellow
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
        },
        colorSecondary: {
          '&.Mui-checked': {
            '& + .MuiSwitch-track': {
              backgroundColor: '#FFB81C',
            },
          },
        },
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#2D3047',
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#F7A41D', // Changed from blue to mustard yellow
        },
        colorSecondary: {
          backgroundColor: '#FFB81C',
          color: '#2D3047',
        },
      }
    },
  },
  typography: {
    fontFamily: [
      '"Inter"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 700,
      letterSpacing: '-0.01em',
    },
    h2: {
      fontWeight: 700,
      letterSpacing: '-0.01em',
    },
    h3: {
      fontWeight: 600,
      letterSpacing: '-0.01em',
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 500,
    },
    subtitle2: {
      fontWeight: 500,
    },
    button: {
      fontWeight: 600,
      letterSpacing: '0.01em',
    },
  },
  transitions: {
    easing: {
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      easeOut: 'cubic-bezier(0, 0, 0.2, 1)',
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeInOutSharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  shape: {
    borderRadius: 10,
  },
  shadows: [
    'none',
    '0 2px 4px rgba(0,0,0,0.05)',
    '0 4px 8px rgba(0,0,0,0.05)',
    '0 6px 12px rgba(0,0,0,0.08)',
    '0 8px 16px rgba(0,0,0,0.08)',
  ]
});

export default newTheme;

