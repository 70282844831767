import React from "react";
import MenuHeader from "../components/Digital Menu/Menu/MenuHeader";
import MenuTable from "../components/Digital Menu/Menu/MenuTable";

function Menu() {
  return (
    <div
      style={{
        minWidth: "1480px",
        overflow: "auto",
        whiteSpace: "nowrap",
        display: "inline-block",
      }}
    >
      <MenuHeader />
      <MenuTable />
    </div>
  );
}

export default Menu;
