import React, { useState } from 'react';
import {
    Button,
    Drawer,
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import { useEffect } from 'react';

const ViewPermissionButton = (props) => {
    const { empId,  setSuccess, setError , fetchEmployees} = props;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [roles, setRoles] = useState([]);
    const [selectedRoleId, setSelectedRoleId] = useState(); // Default selected role
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;
    const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
      };
    const [loading, setLoading] = useState(true);
    const handleToggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleRoleChange = (event) => {
        setSelectedRoleId(event.target.value);
    };

    const selectedRole = roles.find((role) => role.id === selectedRoleId);


    const fetchRoles = () => {
        axios.get(`${process.env.REACT_APP_API_BACKEND}/api/getRoles/${userInfo.currentBranchId}`, config).then((response) => {
            setRoles(response.data);
            setLoading(false);
        }
        );
    }

    const fetchUserRole = () => {
        axios.get(`${process.env.REACT_APP_API_BACKEND}/api/employee/getRole/${empId}`, config).then((response) => {
            setSelectedRoleId(response.data.id);
        }
        );
    }
    useEffect(() => {
        fetchRoles();
        fetchUserRole();
    }
        , []);

    const assignRole = () => {
        axios.post(`${process.env.REACT_APP_API_BACKEND}/api/employee/assignRole/${empId}`, { roleId: selectedRoleId }, config).then((response) => {
            setSuccess(response.data.message);
            setError("");
            setTimeout(() => {
                setSuccess("");
            }, 3000);
            fetchEmployees();
        }).catch((error) => {
            setError(error);
            setSuccess("");
            setTimeout(() => {
                setError("");
            }, 3000);
            fetchEmployees();
        }
        );
    }


    return (
        <>
            <Button variant="contained" color="primary" onClick={handleToggleDrawer} sx={{fontSize: 12}} >
                View Permissions
            </Button>
            <Drawer anchor="right" open={isDrawerOpen} onClose={handleToggleDrawer} >
                <Box p={4} style={{ width: 400, marginTop: 50 }}>
                    <Typography variant="h5" gutterBottom>
                        View Permissions
                    </Typography>
                    <FormControl fullWidth variant="outlined" style={{ marginBottom: 16 }}>
                        <InputLabel>Roles</InputLabel>
                        <Select value={selectedRoleId} onChange={handleRoleChange} label="Roles">
                            {roles && roles.map((role) => (
                                <MenuItem key={role.id} value={role.id}>
                                    {role.role}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {selectedRole && (
                        <div>
                            <Typography variant="h6" gutterBottom>
                                Permissions Summary for {selectedRole.name}
                            </Typography>
                            <Grid container spacing={2}>
                                {selectedRole.permissions.map((permission, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Typography variant="body1">
                                            {permission.module}:
                                            <span style={{ marginLeft: 10 }}>
                                                Read: {permission.read ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}
                                            </span>
                                            <span style={{ marginLeft: 10 }}>
                                                Write: {permission.write ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}
                                            </span>
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )}
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={assignRole}>
                            Update
                        </Button>
                        <Button variant="outlined" onClick={handleToggleDrawer}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

export default ViewPermissionButton;
