import React from "react";
import DoneIcon from "@mui/icons-material/Done";

const styles = {
  largeIcon: {
    width: 60,
    height: 60,
  },
};

function Registered() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        flexDirection: "column",
      }}
    >
      <div>
        <DoneIcon style={{ color: "green", fontSize: 60 }} />
      </div>
      <div style={{ fontSize: "1.5rem", fontWeight: "600" }}>
        You have successfully submitted the form please wait for your manager to
        activate your account.
      </div>
    </div>
  );
}

export default Registered;
