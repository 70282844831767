import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  IconButton,

} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import axios from 'axios';
import ShiftTimeLine from '../components/Employees/Shifts/ShiftTimeLine';
import { AccessTime, Bolt, CalendarToday, HistoryToggleOff } from '@mui/icons-material';
import ErrorAlerts from '../components/ErrorAlert';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { ExpandMore, PlayArrow } from "@mui/icons-material";
import "./ShiftTimeline/Shifts.css";
import ShiftCalender from './ShiftTimeline/ShiftCalender';
import { useTheme } from "@mui/material";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  boxShadow: "none",
  margin: "10px 0",
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(90, 89, 89, 0)'
      : 'rgba(0, 0, 0, 0)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));


const TimeLine = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [employeeData, setEmployeeData] = useState({});
  const [endDate, setEndDate] = useState(null);
  const [shiftClusterByMonth, setShiftClusterByMonth] = useState([]);  
  const [expanded, setExpanded] = React.useState(false);  
  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    };

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const theme = useTheme();

  const [error, setError] = useState(null);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };

  const fetchShifts = (startDate, endDate) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BACKEND}/api/employee/shifts/${id}`, {
        ...config,
        params: { startDate, endDate },
      })
      .then((res) => {
        setLoading(false);
        // const shiftsData = res.data.shifts.map((shift) => ({
        //   ...shift,
        //   startDate: moment(shift.startDate).isValid()
        //     ? moment(shift.startDate).local().format('DD-MM-YYYY')
        //     : 'N/A',
        //   startTime: moment(shift.startTime).isValid()
        //     ? moment.utc(shift.startTime).local().format('HH:mm')
        //     : 'N/A',
        //   endTime: moment(shift.endTime).isValid()
        //     ? moment.utc(shift.endTime).local().format('HH:mm')
        //     : 'N/A',
        //   breaks: shift.break || [],
        //   totalBreak: shift.totalBreak || '00:00',
        //   totalShiftLength: shift.totalShiftLength || '00:00',
        //   shiftWithoutBreak: shift.shiftWithoutBreak || '00:00',
        // }));
        // // Apply sorting locally after fetching the shifts
        // const sortedShifts = sortShifts(shiftsData, sortOption);
        // setShifts(sortedShifts);
        // setSummary(res.data.summary);
        setShiftClusterByMonth(res.data.shiftClusterByMonth);
        setEmployeeData(res.data);
        setTimeout(() => {
            setLoading(false);
        }
        , 200);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError(null);
        }, 3000);
        console.error(err);
      });
  };







  useEffect(() => {
    const today = moment().format('YYYY-MM-DD');
    const oneMonthBefore = moment().subtract(1, 'months').format('YYYY-MM-DD');
    setStartDate(oneMonthBefore);
    setEndDate(today);
  }, []);

  return (
    <Box sx={{ padding: 4, minHeight: '100vh' }}>
      {error && <ErrorAlerts error={error} />}
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          marginBottom: 3,
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={startDate ? moment(startDate).toDate() : null}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <input {...params} style={customInputStyle} />}
          />
          <DatePicker
            label="End Date"
            value={endDate ? moment(endDate).toDate() : null}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <input {...params} style={customInputStyle} />}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
        <Button
          variant="contained"
          onClick={() => fetchShifts(startDate, endDate)}
          sx={{
            padding: '10px 20px',
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          Fetch Shifts
        </Button>
      </Box>



      {loading ? (
        <Box sx={{ textAlign: 'center', marginTop: 4 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (

        (shiftClusterByMonth && employeeData && employeeData.user ?
          <>
               <h1>Shifts</h1>
              
               {
                   Object.keys(shiftClusterByMonth).map((key) => {
                       return (
                           <div key={key}>
                               {/* <h1>{key}</h1>
                               <ShiftCalender data={shiftClusterByMonth[key]} month={key.split(" ")[0]} year={key.split(" ")[1]} user={employeeData.user} /> */}
                               <Accordion sx={{backgroundColor: "none", width:"100%"}} expanded={expanded === key} onChange={handleExpand(key)}>
                                   <AccordionSummary expandIcon={<PlayArrow sx={{
                                        color: theme.palette.text.primary,
                                   }} />} aria-controls="panel1a-content" id="panel1a-header">
                                       
                                       <Typography sx={{
                                           backgroundColor: theme.palette.text.secondary,
                                           width: "max-content",
                                           borderRadius: "10px",
                                           padding: "5px",
                                       }}>{key}</Typography>
                                   </AccordionSummary>
                                   <Box sx={{ overflow:"auto"}}>
                                   <ShiftCalender data={shiftClusterByMonth[key]} month={key.split(" ")[0]} year={key.split(" ")[1]} user={employeeData.user} />
                                   </Box>
                               </Accordion>


                           </div>
                       )
                   }
                   )
               
               }
          </>
           :
           <p>No shifts found</p>
       )

      )}





    </Box>
  );
};

const customInputStyle = {
  padding: '10px',
  borderRadius: '8px',
  border: '1px solid #757575',
  backgroundColor: '#f9f9f9',
  color: '#000',
  width: '100%',
};

export default TimeLine;
