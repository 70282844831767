import { createTheme } from "@mui/material";

const newTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#DAA520", // Golden Yellow
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#2C3E50", // Dark Slate
      contrastText: "#ffffff",
    },
    background: {
      default: "#FAF3E0", // Warm light background
      paper: "#ffffff",
    },
    text: {
      primary: "#2C3E50", // Dark Slate
      secondary: "#DAA520", // Golden Yellow
    },
    success: {
      main: "#28A745",
    },
    error: {
      main: "#DC3545",
    },
    warning: {
      main: "#FFC107",
    },
    info: {
      main: "#17A2B8",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontWeight: "bold",
          padding: "10px 20px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          padding: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "#DAA520",
          borderRadius: "8px",
        },
        indicator: {
          backgroundColor: "#2C3E50",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "#ffffff",
          color: "#2C3E50",
          borderRadius: "10px",
          padding: "20px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: "500",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          backgroundColor: "#ffffff",
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#DAA520",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#DAA520",
            },
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      color: "#2C3E50",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: "#2C3E50",
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: "600",
      color: "#2C3E50",
    },
    body1: {
      fontSize: "1rem",
      color: "#2C3E50",
    },
  },
});

export default newTheme;
