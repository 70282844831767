import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useLocation, useHistory, Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../actions/userActions";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../components/ErrorAlert";
import SuccessAlerts from "../components/SuccessAlert";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function Signup() {
  const [value, setValue] = useState("/signup");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userRegister = useSelector((state) => state.userRegister);
  const userLogin = useSelector((state) => state.userLogin);
  const { error, success, loading } = userRegister;
  const { userInfo } = userLogin;
  const theme = useTheme();
  
  // Redirect if already logged in
  useEffect(() => {
    if (userInfo) {
      history.push("/");
    }
  }, [history, userInfo]);
  
  const submitHandler = (e) => {
    e.preventDefault();
    
    if (!email) {
      setErrorMessage("Please provide an email");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    } else if (!password) {
      setErrorMessage("Please provide a password");
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setErrorMessage("");
        }, 5000);
      }
    } else {
      dispatch(register(email.toLowerCase(), password));
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 5000);
      }
    }
  };
  
  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(/images/login-bg.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: 'hidden',
        overflowX: 'hidden'
      }}
    >
      <Card
        sx={{
          width: {
            xs: 'calc(100% - 32px)', // Full width minus padding on mobile
            sm: '400px'              // Fixed width on larger screens
          },
          m: 2,
          backgroundColor: "#ffffff8d",
          backdropFilter: "blur(10px)",
          boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: {
            xs: 'calc(100vh - 32px)', // Full height minus padding on mobile
            sm: '650px'               // Increased max height
          }
        }}
      >
        <CardContent
          sx={{
            p: { xs: 2.5, sm: 3 }, // Increased padding
            '&:last-child': { pb: { xs: 2.5, sm: 3 } },
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            gap: 2 // Increased gap between direct children
          }}
        >
          {/* Logo and Title - more compact */}
          <Box sx={{ textAlign: 'center', mb: 1 }}>
            <img
              src="/images/empstar-black.png"
              alt="logo"
              style={{ 
                height: '55px',
                marginBottom: '16px',
              }}
            />
            <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
              Create Account
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
              Join our team and start managing your workplace
            </Typography>
          </Box>

          {/* Error/Loading Display */}
          {(loading || (show && (error || errorMessage || success))) && (
            <Box sx={{ my: 1 }}>
              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size={24} />
                </Box>
              )}
              {show && (error || errorMessage) && <ErrorAlert error={error || errorMessage} />}
              {show && success && <SuccessAlerts success={success} />}
            </Box>
          )}

          {/* Tabs - more compact */}
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            sx={{
              mb: 1,  // Increased margin for better spacing
              backgroundColor: "#ffffff41",
              '& .MuiTabs-indicator': { backgroundColor: theme.palette.secondary.main },
              '& .MuiTab-root': { color: theme.palette.text.primary },
              '& .Mui-selected': { color: theme.palette.secondary.main }
            }}
          >
            <Tab 
              value="/login" 
              label="Login" 
              sx={{ 
                minHeight: '42px',
                py: 1.5,
                px: 3
              }} 
            />
            <Tab 
              value="/signup" 
              label="Sign Up" 
              sx={{ 
                minHeight: '42px',
                py: 1.5,
                px: 3
              }} 
            />
          </Tabs>

          {/* Form - more compact spacing */}
          <Box 
            component="form" 
            onSubmit={submitHandler}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2 // Consistent gap between form elements
            }}
          >
            <TextField
              fullWidth
              label="Email Address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              variant="outlined"
              size="medium"
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon />
                  </InputAdornment>
                )
              }}
            />

            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              variant="outlined"
              size="medium"
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                )
              }}
            />

            <Typography 
              variant="body2" 
              sx={{ 
                color: theme.palette.text.contrastText,
                textAlign: 'center',
                fontSize: '0.75rem',
                mt: 1
              }}
            >
              By signing up, you agree to our terms of service and privacy policy.
            </Typography>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{
                py: 1.5,
                px: 3,
                fontSize: '1rem',
                fontWeight: 600,
                borderRadius: 1.5,
                textTransform: 'none',
                width: '100%',
                mt: 1
              }}
            >
              Create Account
              <ArrowForwardIosIcon sx={{ ml: 1.5, fontSize: '0.9rem' }} />
            </Button>
          </Box>

          {/* Footer - more compact */}
          <Box sx={{ 
            mt: 'auto', 
            pt: 3, // Increased top padding
            textAlign: 'center'
          }}>
            <Typography 
              sx={{ 
                color: theme.palette.text.primary,
                mb: 1
              }}
            >
              Powered by
            </Typography>
            <img
              src="https://crinitis-ms-app-files.s3.ap-southeast-2.amazonaws.com/assets/crinitis-horizontal-black.png"
              style={{ 
                maxWidth: '125px',
                display: 'block',
                margin: '8px auto 0'
              }}
              alt="Powered by logo"
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
