import { Avatar, Button, Card, CardActions, CardContent, Divider, List, ListItem, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import './Dashboard.css';
import axios from "axios";
import { useSelector } from "react-redux";
import { startOfWeek, endOfWeek } from "date-fns";
import { useHistory } from "react-router-dom";
import { LineChart, Tooltip, Line, XAxis, YAxis, Label, ResponsiveContainer, BarChart, CartesianGrid, Legend, Bar } from 'recharts';


const Dashboard = () => {
  const [empCount, setEmpCount] = useState(0);
  const [empCountLoading, setEmployeeLoading] = useState(true);
  const [todayShiftsCount, setTodayShiftsCount] = useState(0);
  const [todayShifts, setTodayShifts] = useState([]);
  const [error, setError] = useState("");
  const [shiftLoading, setShiftLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [dataMax, setDataMax] = useState(0);

  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };

  const url1 = process.env.REACT_APP_API_BACKEND;
  const getDashboardData = async () => {
    await axios.get(`${url1}/api/dashboard`, config).then((res) => {
      setEmpCount(res.data.employeesCount);
      setEmployeeLoading(false);
      setTodayShiftsCount(res.data.workingEmployeesCount);
      setTodayShifts(res.data.currentWorkers);
      setShiftLoading(false);
      setChartData(res.data.chartData);
      //set dataMax to the max value of count in chartData
      let max = 0;
      res.data.chartData.forEach((data) => {
        // if (data.count > max) {
        //   max = data.count;
        // }
        if( max < parseInt(data.count)) {
          max = parseInt(data.count);
        }

      })
      if(max > 0) {
        setDataMax(max+10);
      }
      setChartLoading(false);
    }).catch((err) => {
      setError(err.response.data.message);
    })
  }

  useEffect(() => {
    if (!userInfo.isAdmin) {
      history.push(`/employee/${userInfo.employeeId}`);
    }
    getDashboardData();
  }
    , [userInfo, history]);
  const theme = useTheme();
  return (
    <div className="dashboard" >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <div style={{ display: "flex", flexDirection: "column",height: "45vh", width: "100%"  }}>
          <Card className="" sx={{ boxShadow: 0, borderRadius: "10px", padding: "1rem", margin: "5px", height: "100%" }}>
            <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
              <Typography variant="h6">Total Employees</Typography>
              <Typography variant="h4">{empCountLoading ? 'Loading...' : empCount} </Typography>
              <Button
                sx={{ width: "max-content", alignSelf: "flex-end" }}
                variant="text" color="primary" onClick={() => history.push("/employee")}>View All</Button>
            </div>

          </Card>
          <Card sx={{ boxShadow: 0, borderRadius: "10px", padding: "1rem", margin: "5px", height: "100%" }}>
            <div className="dash-s-count-content" style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
              <Typography variant="h6">Currently Working</Typography>
              <Typography variant="h4">{shiftLoading ? 'Loading...' : todayShiftsCount} </Typography>
              <Button
                sx={{ width: "max-content", alignSelf: "flex-end" }}
                variant="text" color="primary" onClick={() => history.push("/employees/shift")}>View All</Button>
            </div>
          </Card>
        </div>
        <div style={{ display: "flex", flexDirection: "column", height: "45vh", width: "100%"  }}>
          <Card  sx={{ boxShadow: 0, borderRadius: "10px" , padding: "1rem", margin: "5px", height:"100%"}}>
            <Typography variant="h5">Currently Working Employees</Typography>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
              <List sx={{ overflowY: "scroll", height: "100%", marginBottom: "50px"  }}>
                {!shiftLoading && todayShifts.map((shift) => (
                  <ListItem 
                  onClick={() => history.push(`/employee/${shift.id}`)}   
                  key={shift.id} 
                  sx={{ padding: "5px 0px",borderRadius: "0", marginBottom: "5px", padding: "5px" , borderBottom: `1px solid ${theme.palette.miscBg.bg}` }}>
                    <Avatar sx={{ width: "2rem", height: "2rem", fontSize: "0.8rem", marginRight: "10px" }}>{shift.name.charAt(0)}</Avatar>
                    <Typography variant="subtitle1">{shift.name}</Typography>
                   
                  </ListItem>
                ))}
                
              </List>

              {/* <Button
                sx={{ width: "max-content", alignSelf: "flex-end" }}
                variant="text" color="primary" onClick={() => history.push("/employees/shift")}>View All</Button> */}
            </div>




          </Card>
        </div>
      </div>
      <div style={{ height: "44vh", width: "100%" , backgroundColor: theme.palette.background.paper, borderRadius: "10px", padding: "10px" }}>
        <Typography variant="h5">Shifts</Typography>

        {chartLoading ? <div className="chart-loading">Loading...</div> :
          <ResponsiveContainer width="95%" height="90%" style={{ margin: "auto" }}>
                        
          <LineChart data={chartData}>
          <Line type="monotone" dataKey="count" stroke="#8884d8" />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis domain={[0, dataMax]} />
              <Tooltip contentStyle={{backgroundColor: theme.palette.card.bg, color: theme.palette.text.primary, border: "none", borderRadius: "10px"}}/>
          </LineChart>

      </ResponsiveContainer>

        }


      </div>
    </div>
  );
}

export default Dashboard;
