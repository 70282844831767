import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Divider } from '@mui/material'
function TableLoader() {
  return (
    <>
    <Skeleton height={60} sx={{ borderRadius: 0 }} animation="wave" />
    <Divider />
    <Skeleton height={60} animation="wave" sx={{ borderRadius: 0 }} />
    <Divider />
    <Skeleton height={60} animation={false} sx={{ borderRadius: 0 }} />
    </>
  )
}

export default TableLoader