import axios from "axios";
import { useEffect } from "react";


import React from 'react';
import { Grid, Paper, Typography, Box, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from "react";
import EmployeeScheduleTable from "./Schedule/EmployeeScheduleTable";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    scheduleBox: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));



const EmployeeScheduleList = ({ id }) => {
    const classes = useStyles();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
   
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
    };

    const fetchTimeLine = () => {
        axios.get(`${process.env.REACT_APP_API_BACKEND}/api/schedule/employee/${id}`, config)
        .then((res) => {
            res.data.employeeSchedules.sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            });
            setData(res.data);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        fetchTimeLine();
    }
    , []);


    return (
        <div className={classes.root}>
           {loading ?
                (<Skeleton variant="rect"  height={118} />)
                :
                (data && data.employeeSchedules &&
                    <div >
                   <EmployeeScheduleTable employeeSchedules={data.employeeSchedules} />
                   </div>
                   )
            }
        </div>
    );
};


export default EmployeeScheduleList;
