import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MiniDrawer from "./components/Navbar/Navbar";
import SignUp from "./Pages/Signup";
import Login from "./Pages/Login";
import ProtectedRoutes from "./components/ProtectedRoutes";
import {  useSelector } from "react-redux";
import theme from "./theme";
import { ThemeProvider } from "@mui/system";
import UserSignup from "./Pages/UserSignup";
import UserLogin from "./Pages/UserLogin";
import CheckCurrentBranch from "./components/User/CheckCurrentBranch";
import ForgotPassword from "./Pages/ForgotPassword";
import NewPassword from "./Pages/NewPassword";
import newTheme from "./newTheme";
import lightTheme from "./lightTheme";
import CallBack from "./components/CallBack";


function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  return (
    <div>
      <ThemeProvider theme={newTheme}>
        <Router>
            <CheckCurrentBranch />
          <Switch>
            <Route path="/signup" component={SignUp} />
            <Route path="/user/signup" component={UserSignup} />
            <Route path="/user/login" component={UserLogin} />
            <Route path="/login" component={Login} />
            <Route path="/reset-password/:post" component={ForgotPassword} />
            <Route path="/new-password/:post" component={NewPassword} />
            <Route path="/callback" component={CallBack} />
            <ProtectedRoutes>
              <MiniDrawer />
            </ProtectedRoutes>
            {/* <ProtectedRoutes path="/" exact component={Dashboard} />
            <ProtectedRoutes path="/employee" component={Employee} />
            <ProtectedRoutes path="/employee/:id" component={EmployeeDetail} />
            <ProtectedRoutes path="/company" component={Information} />
            <ProtectedRoutes path="/registered" component={Registered} />
            <ProtectedRoutes path="/inactive" component={Inactive} />
            <ProtectedRoutes path="/order/insights/sales" component={Sales} /> */}
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
