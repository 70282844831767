import { createTheme } from "@mui/material";

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: "#003c86",
    },
    secondary: {
      main: "#000000",
    },
    background: {
      default: "#dffffc",
      paper: "#ffffffea",
      alert: "#ff0000",
    },
    text: {
      primary: "#000000",
      secondary: "#ffffff",
    },
    card: {
      bg: "#f0f0f0",
      text: "#000000",
      alt: "#e1e1e1",
    },
    dialog: {
      primary: "#000000",
      secondary: "#ffffff",
      paper: "#ffffff",
      bg : "#f0f0f0",
    },
    button: {
      blue: "#4e95ff",
      red: "#ff0000",
      grey: "#d6d6d6",
    },
    success: {
      main: "#4FFFB0",
    },
    error: {
      main: "#ff0000",
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#f0f0f0',
          color: '#000000',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        root: {
          color: '#2196f3',
          borderRadius: 0,
          borderWidth: 0,
          borderColor: '#2196f3',
          border: '0px solid',
        },
        weekNumber: {
          color: '#2196f3 !important',
        },
        weekNumberLabel: {
          color: '#2196f3 !important',
        },
        weekDayLabel: {
          color: '#2196f3 !important',
        },
        switchHeader: {
          color: '#2196f3 !important',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: '#7c7c7c',
          borderRadius: 0,
          borderWidth: 0,
          borderColor: '#2196f3',
          border: '0px solid',
        },
        dayOutsideMonth: {
          color: '#000000',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchViewIcon: {
          color: '#2196f3 !important',
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          color: '#2196f3 !important',
          '& .MuiSvgIcon-root': {
            color: '#2196f3 !important',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default lightTheme;
