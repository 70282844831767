import React from "react";


const styles = {
  largeIcon: {
    width: 60,
    height: 60,
  },
};

function Inactive() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        flexDirection: "column",
      }}
    >
      <div style={{ fontSize: "1.5rem", fontWeight: "600" }}>
        Your account has been deactivated by your manager please contact him.
      </div>
    </div>
  );
}

export default Inactive;
