import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Circle, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const MapComponent = ({ location, draggable, onMarkerDrag, markerPosition, radius, markerName }) => {
    const [currentLocation, setCurrentLocation] = useState(null);

    const handleMarkerDrag = (e) => {
        const newPosition = e.target.getLatLng();
        onMarkerDrag({
            lat: newPosition.lat,
            lon: newPosition.lng
        });
    };

    return (
        <MapContainer
            key={`${location.lat}-${location.lon}`}
            center={[location.lat, location.lon]}
            zoom={15}
            style={{ height: '500px', width: '100%', borderRadius: '20px' }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            {/* Render draggable marker and circle if draggable is true */}
            {draggable && (
                <>
                    <Marker position={[markerPosition.lat, markerPosition.lon]} draggable={true} eventHandlers={{ dragend: handleMarkerDrag }}>
                        <Popup>{markerName}</Popup>
                    </Marker>
                    <Circle center={[markerPosition.lat, markerPosition.lon]} radius={radius} />
                </>
            )}

            {/* Render current location marker if available */}
            {currentLocation && (
                <Marker position={[currentLocation.lat, currentLocation.lon]}>
                    <Popup>Your Location</Popup>
                </Marker>
            )}

        </MapContainer>
    );
};

export default MapComponent;
