import React, { useState, useEffect } from "react";
import { 
  Button, 
  Divider, 
  InputAdornment, 
  Paper, 
  Snackbar, 
  TextField 
} from "@mui/material";
import { withStyles } from "@material-ui/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CreateShift from "./CreateShift";
import { DateRangePicker } from 'materialui-react-daterange-picker';
import ExportCSV from "./ExportCSV";
import "./ShiftHeader.css";
import { useTheme } from "@emotion/react";


// custom styling for input text field
const styles = (theme) => ({
  input: {
    height: 40,
    paddingRight: 0,
  },
});

const ShiftsHeader = withStyles(styles)((props) => {
  const { classes, firstday, shiftArray, lastday, setDateRange, startDay, month, startMonth, endDay, endMonth, dateRange, allShifts, error,
    setError,
    success,
    setSuccess,
    fetchShifts,
    setShiftArray,
    userInfo,
    search,
    setSearch
  } = props;
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState("All");
  const [open, setOpen] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState("");
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationBg, setNotificationBg] = useState("");
  const theme = useTheme();

  const toggle = () => setOpen(!open);

  const saveDateRange = () => {
    sessionStorage.setItem("dateRange", JSON.stringify(dateRange));
  }
  const change = (range) => {
    setDateRange(range);
    saveDateRange();
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const goPrevious = () => {
    let startday = dateRange.startDate;
    let endday = dateRange.endDate;
    startday.setDate(startday.getDate() - 7);
    endday.setDate(endday.getDate() - 7);
    setDateRange({ startDate: startday, endDate: endday })
    saveDateRange();
  }
  const goNext = () => {
    let startday = dateRange.startDate;
    let endday = dateRange.endDate;
    startday.setDate(startday.getDate() + 7);
    endday.setDate(startday.getDate() + 7);
    setDateRange({ startDate: startday, endDate: endday })
    saveDateRange();
  }

  const searchHandler = (e) => {
    setSearch(e.target.value);

    if (!e.target.value || e.target.value.trim() === "") {
      setShiftArray(allShifts);
      props.setIsSearch(false);
      return;
    }

    const searchText = e.target.value.toLowerCase();

    const filteredData = {};

    Object.keys(allShifts).forEach((deptName) => {
      filteredData[deptName] = allShifts[deptName].filter((shift) => {
        const emailMatches = shift.email && shift.email.toLowerCase().includes(searchText);
        const fnameMatches = shift.employeeDetail.fname && shift.employeeDetail.fname.toLowerCase().includes(searchText);
        const lnameMatches = shift.employeeDetail.lname && shift.employeeDetail.lname.toLowerCase().includes(searchText);
        return emailMatches || fnameMatches || lnameMatches;
      });
    });

    const filteredDataWithoutEmptyObjects = Object.keys(filteredData).reduce((acc, deptName) => {
      if (filteredData[deptName].length > 0) {
        acc[deptName] = filteredData[deptName];
      }
      return acc;
    }, {});

    setShiftArray(filteredDataWithoutEmptyObjects);
    props.setIsSearch(true);
  };

  const filterHandler = (e) => {
    setStatus(e.target.value);

    if (e.target.value === "All") {
      props.setShiftArray(allShifts);
    } else if (e.target.value === "Approved") {
      const filteredShifts = {};
      Object.keys(shiftArray).forEach((deptName) => {
        filteredShifts[deptName] = shiftArray[deptName].map((employee) => ({
          ...employee,
          shifts: employee.shifts.filter((shift) => shift.approved),
        }));
      });
      props.setShiftArray(filteredShifts);
    } else if (e.target.value === "Unconfirmed") {
      const filteredShifts = {};
      Object.keys(allShifts).forEach((deptName) => {
        filteredShifts[deptName] = allShifts[deptName].map((employee) => ({
          ...employee,
          shifts: employee.shifts.filter((shift) => !shift.approved),
        }));
      });
      props.setShiftArray(filteredShifts);
    }
    else {
      const filteredShifts = {};
      Object.keys(allShifts).forEach((deptName) => {
        filteredShifts[deptName] = allShifts[deptName].map((employee) => ({
          ...employee,
          shifts: employee.shifts.filter((shift) => shift.status === e.target.value),
        }));
      });
      props.setShiftArray(filteredShifts);
    }
  };

  useEffect(() => {
    if (success) {
      setNotificationMsg(success);
      setNotificationBg("#4caf50");
      setNotificationOpen(true);
      setTimeout(() => {
        setNotificationOpen(false);
        setSuccess("");
      }, 3000);
    }
    if (error) {
      setNotificationMsg(error);
      setNotificationBg("#f44336");
      setNotificationOpen(true);
      setTimeout(() => {
        setNotificationOpen(false);
        setError("");
      }, 3000);
    }
  }, [success, error, setSuccess, setError]);

  return (
    <div>
      <Paper
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
          flexDirection: "column",
          borderCollapse: "separate",
        }}
      >
        <Snackbar
          open={notificationOpen}
          onClose={() => setNotificationOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            top: { md: 75 }
          }}
          onExited={() => setNotificationBg("")}
        >
          <div style={{ backgroundColor: notificationBg, color: "#fff", padding: "10px", borderRadius: "5px" }}>
            {notificationMsg}
          </div>
        </Snackbar>

        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
          <div>
            <div style={{ fontSize: "1.2rem", fontWeight: "500", marginBottom: "0.5rem" }}>
              Employee Shifts
            </div>
            <div>
              <span style={{ backgroundColor: theme.palette.success.main, fontWeight: "500", color: theme.palette.text.secondary, margin: "5px", borderRadius: "5px", padding: "2px 4px" }}>
                {Object.values(shiftArray).reduce((acc, dept) => {
                  return acc + dept.reduce((sum, employee) => {
                    return sum + employee.shifts.filter(shift => shift.approved).length;
                  }, 0);
                }, 0)} approved
              </span>
              <span style={{ backgroundColor: theme.palette.info.main, fontWeight: "500", color: theme.palette.text.secondary, margin: "5px", borderRadius: "5px", padding: "2px 4px" }}>
                {Object.values(shiftArray).reduce((acc, dept) => {
                  return acc + dept.reduce((sum, employee) => {
                    return sum + employee.shifts.filter(shift => !shift.approved).length;
                  }, 0);
                }, 0)} unconfirmed
              </span>
            </div>
          </div>
          <div>
            <Stack spacing={1} direction="row" justifyContent="right">
              <ExportCSV 
                shiftArray={shiftArray} 
                dateRange={dateRange} 
                branchName={userInfo.branchName} 
              />
              {userInfo.isAdmin || (Object.keys(userInfo.permissionData).length !== 0 && 
                userInfo.permissionData.ShiftCreate && 
                userInfo.permissionData.ShiftCreate.read === true) ?
                <CreateShift 
                  fetchShifts={fetchShifts} 
                  success={success} 
                  error={error} 
                  setSuccess={setSuccess} 
                  setError={setError} 
                /> : null
              }
            </Stack>
          </div>
        </div>

        <Divider sx={{ margin: "0.5rem 0" }} />

        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
          <div>
            <span>Search by employee</span>
            <br />
            <TextField
              id="outlined-basic"
              value={search}
              onChange={searchHandler}
              style={{ width: "250px" }}
              autoComplete="off"
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                "& .MuiInputBase-input": {
                  backgroundColor: theme.palette.card.bg,
                  padding: "10px"
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme.palette.text.primary }} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
            <div style={{ display: 'none' }}>
              <input 
                type="text" 
                id="PreventChromeAutocomplete"
                name="PreventChromeAutocomplete" 
                autoComplete="address-level4" 
              />
            </div>
          </div>
          <div>
            <Stack spacing={2} direction="row" justifyContent="right" alignItems="flex-end">
              <div>
                <span style={{ fontWeight: "500" }}>Status</span>
                <div>
                  <Select
                    size="small"
                    style={{ width: "10rem" }}
                    value={status}
                    onChange={filterHandler}
                    sx={{
                      borderRadius: 1,
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                      "& .MuiSelect-icon": {
                        color: theme.palette.text.primary
                      }
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: theme.palette.card.bg,
                          color: theme.palette.text.primary
                        }
                      }
                    }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Approved">Approved</MenuItem>
                    <MenuItem value="Unconfirmed">Unconfirmed</MenuItem>
                  </Select>
                </div>
              </div>

              <div>
                <span style={{ fontWeight: "500" }}>Date Range</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    size="small"
                    onClick={goPrevious}
                    variant="contained"
                    sx={{ minWidth: "40px", padding: "4px", height: "40px" }}
                  >
                    <ArrowBackIcon fontSize="small" />
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ 
                      textTransform: "none", 
                      fontWeight: "500", 
                      margin: "0 0.5rem",
                      color: theme.palette.text.primary
                    }}
                    onClick={handleClickOpen}
                  >
                    {(startDay + month[startMonth] === endDay + month[endMonth]) 
                      ? (startDay + " " + month[startMonth]) 
                      : (startDay + " " + month[startMonth] + " - " + endDay + " " + month[endMonth])}
                  </Button>
                  <div style={{ position: "relative" }}>
                    {open && <div className="date-range-picker-container"></div>}
                    <div className="date-range-picker">
                      <DateRangePicker
                        open={open}
                        toggle={toggle}
                        onChange={change}
                        initialDateRange={{ startDate: firstday, endDate: lastday }}
                        color={theme.palette.primary.main}
                        wrapperClassName="date-range-picker-wrapper"
                      />
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={goNext}
                    sx={{ 
                      minWidth: "40px", 
                      padding: "4px",
                      height: "40px"
                    }}
                  >
                    <ArrowForwardIcon fontSize="small" />
                  </Button>
                </div>
              </div>
            </Stack>
          </div>
        </div>
      </Paper>
    </div>
  );
});

export default ShiftsHeader;
