import { Preview } from "@mui/icons-material";
import { Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper } from "@mui/material";
import React from "react";


const ShiftsQuickView = (props) => {
    const { employee } = props;
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const shifts = employee.shifts;

    const formatTime = (date) => {
        const d = new Date(date);
        return d.toLocaleTimeString("en-GB");
    };

    

    const convertS3ToCloudFront = (url) => {
        const cloudFrontDomain = 'https://d2k8ghk99lpcqm.cloudfront.net/';
        const s3Domain = 'https://cms-shifts.s3.ap-southeast-2.amazonaws.com/';
        
        if (url.startsWith(s3Domain)) {
            return url.replace(s3Domain, cloudFrontDomain);
        } else {
            console.error('Invalid URL format');
            return url;
        }
    };

    return (
        <div>
            <Preview onClick={handleClick} style={{ cursor: 'pointer' }} />
            <Dialog 
                open={open} 
                onClose={handleClose} 
                PaperProps={{ 
                    style: { 
                        padding: "20px", 
                        maxHeight: "500px", 
                        overflowY: "auto", 
                        width: '80%', 
                        maxWidth: '1000px' 
                    } 
                }}
            >
                <div>
                    <h1 style={{ marginBottom: '20px', textAlign: 'center' }}>Shifts Overview</h1>
                    <TableContainer component={Paper} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Start Image</strong></TableCell>
                                    <TableCell><strong>End Image</strong></TableCell>
                                    <TableCell><strong>Time (Start - End)</strong></TableCell>
                                    <TableCell><strong>Shift Status</strong></TableCell>
                                    <TableCell><strong>Actions</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {shifts.map((shift) => (
                                    <TableRow key={shift._id}>
                                        <TableCell>
                                            {shift.startImage && (
                                                <img 
                                                    src={convertS3ToCloudFront(shift.startImage)} 
                                                    alt="start" 
                                                    style={{ width: "150px", borderRadius: "8px" }} 
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {shift.endImage && (
                                                <img 
                                                    src={convertS3ToCloudFront(shift.endImage)} 
                                                    alt="end" 
                                                    style={{ width: "150px", borderRadius: "8px" }} 
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <p>{formatTime(shift.startTime)} - {formatTime(shift.endTime)}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <p>{shift.status ? shift.status : "Pending"}</p>
                                        </TableCell>
                                        <TableCell>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                onClick={() => alert('Approved shift for ' + shift.id)}
                                            >
                                                Approve
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Dialog>
        </div>
    );
};

export default ShiftsQuickView;
