import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";

// custom style for input text field
const styles = (theme) => ({
  input: {
    height: 40,
    paddingRight: 0,
  },
});

const CRMContactHeader = withStyles(styles)((props) => {
  const { classes } = props;
  const [search, setSearch] = useState("");
  return (
    <div>
      <div
        className="header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="left-container">
          <div style={{ fontSize: "1.4rem" }}>Contacts</div> <br />
          <span>Search by name / email / mobile / identifier / public ID</span>
          <br />
          <TextField
            id="outlined-basic"
            style={{ width: "450px" }}
            value={search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0 5px 5px 0",
                      backgroundColor: "#1a237e",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      height: "2.5rem",
                    }}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
              className: classes.input,
            }}
            variant="outlined"
          />
        </div>
        <div className="right-container" style={{ marginLeft: "1rem" }}>
          {/* <Button variant="contained" style={{backgroundColor:"#1a237e",textTransform:"capitalize", fontWeight:"600"}}><AddIcon/> Invite employee</Button> */}
          <Stack spacing={1} direction="row" justifyContent="right">
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#22baee",
                fontWeight: "500",
              }}
              startIcon={<ImportExportIcon />}
            >
              Export
            </Button>
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#4caf50",
                fontWeight: "500",
              }}
              startIcon={<VisibilityIcon />}
            >
              View Sign In
            </Button>
            <Link to="/crm/contact/create">
              <Button
                variant="contained"
                style={{ textTransform: "none", fontWeight: "500" }}
                startIcon={<AddIcon />}
              >
                Add Contact
              </Button>
            </Link>
          </Stack>

          <Stack spacing={1} direction="row" justifyContent="right">
            <div>
              <div style={{ marginTop: "1.2rem" }}>Subscribed</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="All"
                  style={{ width: "12rem" }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Inactive"}>Inactive</MenuItem>
                  <MenuItem value={"Onboarding"}>Onboarding</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <div style={{ marginTop: "1.2rem" }}>Source</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="All"
                  style={{ width: "12rem" }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Inactive"}>Inactive</MenuItem>
                  <MenuItem value={"Onboarding"}>Onboarding</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <div style={{ marginTop: "1.2rem" }}>Type</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="All"
                  style={{ width: "12rem" }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Inactive"}>Inactive</MenuItem>
                  <MenuItem value={"Onboarding"}>Onboarding</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <div style={{ marginTop: "1.2rem" }}>Status</div>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="All"
                  style={{ width: "12rem" }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Inactive"}>Inactive</MenuItem>
                  <MenuItem value={"Onboarding"}>Onboarding</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Stack>
          {/* <FormControl fullWidth size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value=""
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Active"}>Active</MenuItem>
              <MenuItem value={"Inactive"}>Inactive</MenuItem>
              <MenuItem value={"Onboarding"}>Onboarding</MenuItem>
            </Select>
          </FormControl> */}
        </div>
      </div>
    </div>
  );
});

export default CRMContactHeader;
