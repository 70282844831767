import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Modal, Tab, useTheme } from "@mui/material";
import { Typography } from "@mui/material";
import "./ShiftsTable.css";
import { fontSize, textAlign } from "@mui/system";
import ShiftBox from "./ShiftBox";
import DateRange from "@mui/icons-material/DateRange";
import { withStyles, makeStyles } from "@material-ui/styles";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import SuccessAlerts from "../../SuccessAlert";
import ErrorAlerts from "../../ErrorAlert";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";
import ViewOnlyShiftBox from "./ViewOnlyShiftBox";
import ShiftsQuickView from "./ShiftsQuickView";

// custom styling for tables
const tableStyles = makeStyles({
  tableRow: {
    height: 8,
    borderRight: "1px solid #e6e6e6",
  },
  tableCell: {
    padding: "0.4rem",
    width: "180px!important",
    borderRight: "1px solid #e6e6e6",
    maxWidth: "180px",
  },
  bodyCell: {
    padding: "6px 16px",
    marginTop: "100px",
  },
  cell: {
    maxWidth: '180px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 10,
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px 5px",
    border: "1px solid #e6e6e6 !important",
  },
}))(TableCell);

export default function ShiftsTable({
  firstday,
  lastday,
  setDateRange,
  startDay,
  month,
  startMonth,
  endDay,
  endMonth,
  dateRange,
  dayNames,
  shiftArray,
  setShiftArray,
  shiftLoading,
  setShiftLoading,
  allShifts,
  setAllShifts,
  error,
  setError,
  success,
  setSuccess,
  fetchShifts,
}) {
  const classes = tableStyles();
  const [open, setOpen] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [shiftUpdateError, setShiftUpdateError] = useState("");
  const [tableDates, setTableDates] = useState([]);
  const [workersCount, setWorkersCount] = useState({});
  const [totalShiftCount, setTotalShiftCount] = useState(0);
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // reference for scroll effect on load
  const scrollRef = React.useRef(null);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dates = [];
  const theme = useTheme();
  // to fetch all the dates between date range
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  // to format date in the form backend is accepting
  const formatDate = (date) => {
    date = new Date(date);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = date.getFullYear();
    date = yyyy + "-" + mm + "-" + dd;
    return date;
  };
  const formatUTCDate = (myDate) => {
    let isoDateStr = new Date(myDate).toISOString();
    isoDateStr = isoDateStr.substring(0, isoDateStr.indexOf("T"));
    return isoDateStr;
  };

  // scroll effect function
  const scrollToElement = () => {
    scrollRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  const currDate = new Date();

  // custom styling for active and inactive columns
  const activeColumn = {
    active: {
      minWidth: "200px",
      maxWidth: "200px",
      backgroundColor: theme.palette.miscBg.bg2,
    },
    inactive: {
      minWidth: "200px",
      maxWidth: "200px",
      border: "none",
      // backgroundColor: "#f7f7f7",
    },
    grey: {
      // backgroundColor: "#eeeeee",
    },
    extras: {
      minWidth: "180px",
      maxWidth: "180px",
      border: "none",
      padding: "5px 16px",
      // backgroundColor: "#ececec",
      
      backgroundColor: theme.palette.background.paper,
    },

  };

  if (scrollRef && scrollRef.current) {
    scrollToElement();
  }
  useEffect(() => {
    fetchShifts();
    setTableDates(getDatesInRange(dateRange.startDate, dateRange.endDate));
    if (scrollRef && scrollRef.current) {
      scrollToElement();
    }
  }, [dateRange, scrollRef]);

  if (success) {
    setTimeout(() => {
      setSuccess("");
    }, 4000);
  }
  if (error) {
    setTimeout(() => {
      console.log("YE ERROR", error);
      setError("");
    }, 4000);
  }
  const roundOffTime = (hhmmss) => {
    const [hours, minutes, seconds] = hhmmss.split(":");
    const totalSeconds = parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
    const roundedSeconds = Math.round(totalSeconds / 60) * 60;
    const roundedHours = Math.floor(roundedSeconds / 3600);
    const roundedMinutes = Math.floor((roundedSeconds % 3600) / 60);
    const formattedHours = roundedHours.toString().padStart(2, '0');
    const formattedMinutes = roundedMinutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  };

  useEffect(() => {
    calculateWorkersCount();
  }, [shiftArray]);

  const calculateWorkersCount = () => {
    const count = {};
    let totalShiftCount = 0;
    let totalEmployeeCount = 0;
    tableDates.forEach((date) => {
      const dateStr = formatDate(date);
      count[dateStr] = new Set();
    });

    Object.values(shiftArray).forEach((shifts) => {
      shifts.forEach((shift) => {
        shift.shifts.forEach((s) => {
          const shiftDate = formatDate(new Date(s.startTime));
          if (count[shiftDate]) {
            count[shiftDate].add(s.employeeId);
          }
          totalShiftCount++;
        });
        totalEmployeeCount++;
      });
    });

    const workerCountObj = {};
    Object.keys(count).forEach((key) => {
      workerCountObj[key] = count[key].size;
    });
    setWorkersCount(workerCountObj);
    setTotalShiftCount(totalShiftCount);
    setTotalEmployeeCount(totalEmployeeCount);
  };

  const getAvatarName = (employee) => {
    if (employee.employeeDetail) {
      if (employee.employeeDetail.fname && employee.employeeDetail.lname) {
        return employee.employeeDetail.fname.charAt(0).toUpperCase() + employee.employeeDetail.lname.charAt(0).toUpperCase();
      }
      else if (employee.employeeDetail.fname) {
        return employee.employeeDetail.fname.charAt(0).toUpperCase();
      }
      else if (employee.employeeDetail.lname) {
        return employee.employeeDetail.lname.charAt(0).toUpperCase();
      }
    }
    else {
      return employee.email.charAt(0).toUpperCase();
    }
  }
  const getTotalShiftCount = (shift, approved) => {
    let totalHours = 0;
    let totalMinutes = 0;
    let totalSeconds = 0;
    shift.shifts.forEach((s) => {
      if (s.status === 'Completed') {
        if (approved === true && s.approved === true) {
          const [hours, minutes, seconds] = s.shiftWithoutBreak.split(":").map(Number);
          totalHours += hours;
          totalMinutes += minutes;
        }
        else if (approved === false && s.approved === false) {
          const [hours, minutes, seconds] = s.shiftWithoutBreak.split(":").map(Number);
          totalHours += hours;
          totalMinutes += minutes;
        }
      }
    });

    // Calculate the total time in seconds
    const totalMinutesFromSeconds = Math.floor(totalSeconds / 60);
    totalSeconds %= 60;

    const totalHoursFromMinutes = Math.floor((totalMinutes + totalMinutesFromSeconds) / 60);
    totalMinutes = (totalMinutes + totalMinutesFromSeconds) % 60;

    // Format the result as "hh:mm:ss"
    const result = `${String(totalHours + totalHoursFromMinutes).padStart(2, '0')}:${String(totalMinutes).padStart(2, '0')}:${String(totalSeconds).padStart(2, '0')}`;
    return result;
  };




  return (
    <>
      <div
        style={{
          width: "20rem",
          marginBottom: "1rem",
          zIndex: "1000",
          position: "absolute",
          top: 70,
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {error && <ErrorAlerts error={error} />}
        {success && <SuccessAlerts success={success} />}
        {/* {shiftUpdateSuccess && <SuccessAlerts success={shiftUpdateSuccess} />} */}
      </div>
      <Paper>
      <TableContainer
        style={{ marginTop: "0.5rem", }}
        sx={{ height: "70vh", width: "100%" , borderRadius: "5px"}}

      >
        <Table sx={{ maxWidth: "1440px", border:"none" }} stickyHeader aria-label="sticky table" border="1px solid #e6e6e6">
          <TableHead sx={{ '&.MuiTableHead-root': { borderSpacing: "250px" } }}>
            <TableRow sx={{
              height: "75px",
              '&.MuiTableRow-root th:first-child': { borderTopLeftRadius: "0", borderBottomLeftRadius: "0" },
              '&.MuiTableRow-root th:last-child': { borderTopRightRadius: "20px", borderBottomRightRadius: "20px" },
              '&.MuiTableRow-root th': { marginBottom: "50px" }
            }}>
              <TableCell
                align="center"
                style={{
                  padding: "5px 16px",
                  border: "none",
                  backgroundColor: theme.palette.background.paper,
                  minWidth: "200px",
                  maxWidth: "200px",
                }}
                sx={{ '&.MuiTableCell-root': { backgroundColor: theme.palette.background.paper, padding: "0" } }}
              >
                <div style={{
                  height: "75px", width: "100%",
                  backgroundColor: theme.palette.background.default,
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <div>
                    <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '0.9rem' }}>
                    <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '0.9rem' , backgroundColor: theme.palette.background.paper, padding: "7px", borderRadius: "15px", margin:"5px" }}>
                         Total Shifts: {totalShiftCount}
                      </Typography>  
                      <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '0.9rem' , backgroundColor: theme.palette.background.paper, padding: "7px", borderRadius: "15px", margin:"5px" }}>
                         Total Employees: {totalEmployeeCount}
                      </Typography>  
                    </Typography>
                  </div>
                </div>
              </TableCell>
              {tableDates.map((d) =>
                formatDate(currDate) == formatDate(d) ? (
                  <TableCell
                    key={d}
                    ref={scrollRef}
                    align="center"
                    style={activeColumn.active}

                    sx={{ '&.MuiTableCell-root': { 
                      border: "none",
                      padding: "5px 5px", backgroundColor: theme.palette.background.paper } }}
                  >
                    <div style={{
                      height: "75px", width: "100%",
                      backgroundColor: theme.palette.background.default,
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                      , border: `1px solid ${theme.palette.primary.main}`,
                      borderRadius: "20px", padding: "5px"
                    }}>
                      <div>
                        <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '0.9rem' }}>
                          {dayNames[d.getDay()]}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: '600', fontSize: '0.9rem' }}>
                          {d.getDate()} {month[d.getMonth()]}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: '400', fontSize: '0.8rem', color: theme.palette.text.primary, backgroundColor: theme.palette.background.paper, padding: "5px", borderRadius: "15px" }}>
                          Total: {workersCount[formatDate(d)] || 0}
                      </Typography>
                      </div>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell
                    key={d}
                    align="center"
                    style={activeColumn.inactive}
                    sx={{ '&.MuiTableCell-root': { padding: "5px 5px", backgroundColor: theme.palette.background.paper } }}
                  >
                    <div style={{
                      height: "75px", width: "100%",
                      backgroundColor: theme.palette.background.default,
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <div>
                        <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '0.9rem' }}>
                          {dayNames[d.getDay()]}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: '600', fontSize: '0.9rem' }}>
                          {d.getDate()} {month[d.getMonth()]}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: '400', fontSize: '0.8rem', color: theme.palette.text.primary, backgroundColor: theme.palette.background.paper, padding: "5px", borderRadius: "15px" }}>
                        Total: {workersCount[formatDate(d)] || 0}
                        </Typography>
                      </div>
                    </div>
                  </TableCell>
                )
              )}
              <TableCell
                ref={scrollRef}
                align="center"
                style={activeColumn.extras}
              >
                <div style={{
                  height: "75px", width: "100%",
                  backgroundColor: theme.palette.background.default,
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <div>
                <span style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                  TOTAL HOURS
                </span>
                <br />
                <span style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                  Excluding breaks
                </span>
                </div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          {!shiftLoading && shiftArray.length == 0 && (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={tableDates.length}
                  sx={{ textAlign: "center" }}
                >
                  <span
                    style={{
                      display: "block",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3>No shifts found</h3>
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {shiftLoading ? (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={tableDates.length} sx={{ textAlign: "center" }}
                >
                  <span style={{ display: "block", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><TailSpin color={theme.palette.primary.main}  height={120} width={120} /></span>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody sx={{ overflowX: "hidden", borderSpacing: "250px" }}>




              {Object.keys(shiftArray).map((deptName) => (
                <React.Fragment key={deptName}>
                  <TableRow
                    key={deptName}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&.MuiTableRow-root": { padding: "0px" }
                    }}
                    style={{
                      position: "sticky",
                      top: "85px",
                      zIndex: "100",
                      left: "0",
                      //prvent x scroll
                      overflowX: "hidden",
                      border: "none",
                      height: "10px",


                    }}
                  >

                    <TableCell
                      align="left"
                      style={{
                        borderRight: "none",
                        zIndex: "10000",
                        border: "none",
                        padding: "0",
                        position: "sticky",
                        left: "0",

                      }}
                      colSpan={tableDates.length+1}
                    >
                      {/* <span style={{ fontWeight: "600", fontSize: "1.5rem" }}>
                        {deptName}
                      </span> */}
                      <Typography style={{
                        fontWeight: "500", 
                        backgroundColor: theme.palette.miscBg.bg,
                        margin: "5px 10px",

                        padding: "5px", borderRadius: "5px", display: "flex", alignItems: "center", paddingLeft: "10px"
                      }}>
                        <div style={{ position: "sticky", left: "0", display: "flex", alignItems: "center" }}>
                          <div style={{ backgroundColor: `${theme.palette.primary.main}`, height: "1rem", width: "5px", marginRight: "5px", borderRadius: "5px" }}></div>
                          <div>
                            {deptName}
                          </div>
                        </div>
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {shiftArray[deptName].map((shift) => (
                    <TableRow
                      key={shift.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}

                      className={classes.tableRow}
                    >
                      <TableCell
                        key={shift.id}
                        align="center"
                        style={{
                          borderRight: "1px solid #e6e6e6",
                          padding: "5px 10px",
                          
                          
                          margin: "6px",
                        }}
                        className="table-sticky"
                      >
                       <Box 
                       sx={{
                        backgroundColor: theme.palette.miscBg.bg,
                        borderRadius: "10px",
                        padding: "7px",
                       }}>
                       
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          justifyContent: 'flex-between',

                          height: "80px",
                          width: "100%",
                          borderRadius: "10px",
                          padding: "5px",
                          paddingLeft: "10px",

                          }}>
                          <Avatar style={{
                            width: "2.5rem", height: "2.5rem", fontWeight: "500", fontSize: "1rem",
                            borderRadius: "10px"
                          }}
                          >
                            {getAvatarName(shift)}
                          </Avatar>
                          
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            marginLeft: "10px",
                          }}>
                            <Link
                              style={{ textDecoration: "none" , color: theme.palette.text.primary}}
                              to={`/employee/${shift.id}`}   
                            >
                               
                              <Typography variant="body2" sx={{ fontWeight: '400', fontSize: '1.1rem', textAlign: 'left' , hyphens: "auto", wordBreak: "break-word", overflowWrap: "break-word", width: "100%" }}>
                               
                                {shift.employeeDetail.fname ? shift.employeeDetail.fname.charAt(0).toUpperCase() + shift.employeeDetail.fname.slice(1) : shift.email}
                                {shift.employeeDetail.lname ? " " + shift.employeeDetail.lname.charAt(0).toUpperCase() + shift.employeeDetail.lname.slice(1) : ""}
                              </Typography>
                            
                             
                            </Link>
                            
                            
                          </Box>
                          {/* <ShiftsQuickView employee={shift} style={{ marginLeft: "auto" }} /> */}
                        </Box>
                          </Box>
                      </TableCell>
                      {tableDates.map((d, index) => (
                        <TableCell
                          key={d}
                          sx={{
                            margin: "6px",
                            width: "180px",
                            position: "relative",
                          }}
                          align="center"
                          className={classes.tableCell}
                          style={
                            currDate.getDate() == d.getDate()
                              ? activeColumn.active
                              : { backgroundColor: "none" }
                          }
                        >
                          {shift.shifts.map(
                            (s) =>
                              formatDate(s.startDate) === formatDate(d) && (
                                userInfo.isAdmin || Object.keys(userInfo.permissionData).length !== 0 && userInfo.permissionData.Shift.write === true ? (
                                  <ShiftBox
                                    key={s.id}
                                    shift={s}
                                    fetchShifts={fetchShifts}
                                    setSuccess={setSuccess}
                                    setError={setError}
                                    employee={shift.employeeDetail}
                                    email={shift.email}
                                    employeeId={shift.id}

                                  />
                                ) : (
                                  <ViewOnlyShiftBox
                                    key={s.id}
                                    shift={s}
                                    fetchShifts={fetchShifts}
                                    setSuccess={setSuccess}
                                    setError={setError}
                                    employee={shift.employeeDetail}
                                    email={shift.email}
                                    employeeId={shift.employeeId}
                                  />
                                )

                              )
                          )}
                        </TableCell>
                      ))}
                      <TableCell
                        align="center"
                        sx={{ '&.MuiTableCell-root': {  padding: "0 10px", } }}
                      >
                        <Box sx={{
                          backgroundColor: theme.palette.miscBg.bg,
                          borderRadius: "10px",
                          padding: "10px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100px"

                        }}>
                            <Typography variant="body2" sx={{ 
                              fontWeight: '300', 
                              fontSize: '0.9rem',
                              backgroundColor: theme.palette.background.paper,
                              padding: "5px",
                              width: "100%",
                              marginBottom: "10px",
                              borderRadius: "15px",
                               }}>
                            Approved: {roundOffTime(getTotalShiftCount(shift, true))}
                            </Typography>
                            <Typography variant="body2" sx={{ 
                              fontWeight: '300', 
                              fontSize: '0.9rem',
                              backgroundColor: theme.palette.background.paper,
                              padding: "5px",
                              width: "100%",
                              borderRadius: "15px",
                              }}>
                              Pending: {roundOffTime(getTotalShiftCount(shift, false))}
                            </Typography>
                        </Box>
                      </TableCell>


                    </TableRow>
                  ))}
                </React.Fragment>
              )
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      </Paper>
    </>
  );
}

