import { DoneAll, LibraryAdd, LibraryAddCheck } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Dialog, DialogContent, DialogTitle, Skeleton, Slide, Tooltip, Typography, Zoom, useTheme } from '@mui/material';
import React from 'react';

// ... (previous code)

const AnnouncementStats = (props) => {
    const { setOpen, data, loading } = props;
    const timeFormat = (time) => {
        const date = new Date(time);
        return date.toLocaleString();
    }
    const theme = useTheme();
    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={300}>
        <Box sx={{ p: "20px" }}>
            {/* <DialogTitle>Announcement Stats</DialogTitle>
            <DialogContent> */}
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>Announcement Stats</Typography>
            {!loading ? (
                <>
                    <Accordion
                        sx={{ backgroundColor: theme.palette.card.alt, borderRadius: "10px !important", mb: "10px", border: `1px solid ${theme.palette.success.main}`, }}
                        elevation={0}
                        defaultExpanded={true}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography sx={{ display: "flex", alignItems: "center", fontSize: "16px", fontWeight: "500" }}>
                                Read Status
                                
                                    <LibraryAddCheck  sx={{ ml: "10px" , color: theme.palette.success.main}} />
                                
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {data && data.read &&
                                    Object.keys(data.read).map((category, index) => (
                                        <div key={index}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>{category}</Typography>
                                            {/* {data.read[category].map((person) => (
                                                <Typography key={person.id}>{person.name}</Typography>
                                            ))} */}
                                            <Box sx={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                                            {data.read[category].map((person) => (
                                                <Tooltip key={person.id} title={timeFormat(person.readTime)} TransitionComponent={Zoom} placement="top">
                                                <Chip label={person.name} sx={{ m: "5px", backgroundColor: theme.palette.card.bg}} />
                                                </Tooltip>
                                            ))}
                                            </Box>
                                            {index !== Object.keys(data.read).length - 1 && <hr />}
                                        </div>
                                    ))}
                                {/* {(!data || !data.read) && <Typography>No data available</Typography>} */}

                                {(!data || !data.read
                                    || Object.keys(data.read).length === 0) && <Typography>No data available</Typography>}

                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        sx={{
                            backgroundColor: theme.palette.card.alt,
                            borderRadius: "10px !important",
                            border: `1px solid ${theme.palette.error.main}`,
                            mt: "10px",
                            '&:before': {
                                display: 'none',
                            },
                        }}
                        elevation={0}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography sx={{ display: "flex", alignItems: "center", fontSize: "16px", fontWeight: "500" }}>
                                Un-Read Status
                                <LibraryAddCheck color='error' sx={{ ml: "10px" }} />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {data && data.unread &&
                                    Object.keys(data.unread).map((category, index) => (
                                        <div key={index} >
                                            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>{category}</Typography>
                                            <Box sx={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                                            {/* {data.unread[category].map((person) => (
                                                <Typography key={person.id}>{person.name}</Typography>
                                            ))} */}
                                            {data.unread[category].map((person) => (
                                                <Chip key={person.id} label={person.name} sx={{ m: "5px", backgroundColor: theme.palette.card.bg}} />
                                            ))}
                                            </Box>
                                            {index !== Object.keys(data.unread).length - 1 && <hr />}
                                        </div>
                                    ))}
                                {(!data || !data.unread
                                    || Object.keys(data.unread).length === 0) && <Typography>No data available</Typography>}

                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </>
            ) : (
                <>
                    <Skeleton variant="rectangular" sx={{ height: "80px", mb: "10px", borderRadius: "10px" }} />
                    <Skeleton variant="rectangular" sx={{ height: "50px", mb: "10px", borderRadius: "10px" }} />
                </>
            )}

        </Box>
        </Slide>
    );
}

export default AnnouncementStats;
