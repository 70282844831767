import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withStyles, makeStyles } from "@material-ui/styles";
import TablePagination from "@mui/material/TablePagination";
import { Box } from "@mui/material";

// custom style for table
const tableStyles = makeStyles({
  tableRow: {
    height: 20,
  },
  tableCell: {
    padding: "10px 16px",
  },
  bodyCell: {
    padding: "6px 16px",
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 20,
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px 5px",
  },
}))(TableCell);

// custom style for input text field
const styles = (theme) => ({
  input: {
    height: 40,
    paddingRight: 0,
  },
});

const BatchesTable = withStyles(styles)((props) => {
  const tableclasses = tableStyles();
  // state for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // function to handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // function to handle the number of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      {" "}
      <Paper
        sx={{
          marginTop: "1rem",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
      >
        <TableContainer sx={{ width: "100%", overflowX: "hidden" }}>
          <Table sx={{ minWidth: "90vw" }} aria-label="simple table">
            <TableHead>
              <TableRow className={tableclasses.tableRow}>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={tableclasses.tableCell}
                >
                  Inventory name
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={tableclasses.tableCell}
                >
                  Batch date
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={tableclasses.tableCell}
                >
                  Expiry date
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={tableclasses.tableCell}
                >
                  Reference ID
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={tableclasses.tableCell}
                >
                  Order #
                </TableCell>

                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={tableclasses.tableCell}
                >
                  Status
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={tableclasses.tableCell}
                >
                  Quantity
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontWeight: "600" }}
                  className={tableclasses.tableCell}
                >
                  Price
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
      <Box
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ fontWeight: "700" }}
        ></TablePagination>
      </Box>
    </div>
  );
});

export default BatchesTable;
